import React, { Component } from 'react'
import video_path from '../../assets/video/tellus_social_desktop_video.mp4'

export default class IntroductionVideo extends Component {

  continue = () => {
    this.props.closeVideo();
  }

  render() {
    return (
      <div>
        <div className="container">
          <h1 className="video-header" style={{ textAlign: 'center', width: '100%' }}>The Social Desktop</h1>
          <p className="video-txt" style={{ textAlign: 'center', width: '100%' }}>Here’s a quick video to introduce your next task:</p>
          <video className="video" width="640" height="480" controls>
            <source src={video_path} type="video/mp4" ></source>
            Your browser does not support the video tag.
          </video>
          <div className="video-button" onClick={this.continue}>
            <p className="video-btn-txt font-demibold-big">Continue</p>
          </div>
        </div >
      </div>
    )
  }
}
