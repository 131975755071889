import _ from 'lodash'

import actionTypes from '../actions/actionTypes'

export const initialState = {
  interests: [],
  searchedInterests: [],
  savedInterests: [],
}

export default (state = initialState, action) => {

  switch (action.type) {
    case actionTypes.LOGOUT: {
      return initialState
    }
    case actionTypes.GET_INTERESTS: {
      const { data } = action.payload
      return _.assign({}, state, { interests: data })
    }

    case actionTypes.COLLECT_INTERESTS: {
      const { data } = action.payload
      return _.assign({}, state, { searchedInterests: data })
    }
    case actionTypes.SAVE_USER_INTERESTS: {
      return _.assign({}, state, { savedInterests: action.payload })
    }
    default:
      return state
  }

}