import React from 'react'

import './lastPage.css';
import LoadingIndicator from '../ui/loadingIndicator';

const checkStatus = (status, message, onClose) => {
  if (status === "Success") {
    return <h1 className="popUp-txt">Payment successful.<br /> Thank you for completing the survey</h1>

  } else if (status === "") {
    return <h1 className="popUp-txt">processing...</h1>
  } else {
    return <div>
      <h1 className="popUp-txt">{message}</h1>
      <div className="popup-button" onClick={onClose}>
        <p className="btn_text">Close</p>
      </div>
    </div>
  }

}

const PopUp = ({ message, onClose, status }) => {
  // status = "Success"; for test 
  return (
    <div className="popUp-container">
      {checkStatus(status, message, onClose)}
    </div >
  )
}

export default PopUp