import React, { Component } from 'react'
import Modal from 'react-responsive-modal';
import './modalStyles.css'
import { validateEmail } from '../../utils/dataUtils';
import close from '../../assets/svg/group-close.svg';
import add from '../../assets/svg/group-add.svg';
import { getTagColor } from 'utils/stylesUtils'
import tagIcons from './tagIcons'
import attributesIcons from './attributesIcons'
import img from '../../assets/img/flags.png'
import ReactTelInput from 'react-telephone-input';
import '../contactInfo/inputFlagStyle.css';

class contactModal extends Component {
    state = {
        id: '',
        name: '',
        phoneNumber: '',
        emailAddress: '',
        emailValidation: false,
        phoneValidation: false,
        nameValidation: false,
        countryCode: '',
    }

    changeName = (value) => {

        // Here I need to Check is edit part beacuse I don't want to edit with same name or empty string 

        this.setState({
            name: value,
            nameValidation: this.checkValue(value)
        })

    }

    phoneRegex = phone => phone.replace(/[^+\d]+/g, '');

    handleInputChange = (telNumber, selectedCountry) => {
        let value = telNumber;
        let countryCode = selectedCountry.dialCode;

        let phone = this.phoneRegex(value);

        this.setState({
            phoneNumber: phone,
            phoneValidation: this.checkValue(phone),
            countryCode: countryCode
        })
    }

    changePhone = (value) => {
        this.setState({
            phoneNumber: value,
            phoneValidation: this.checkValue(value)
        })
    }

    checkValue = (val) => {
        let value = val.trim('');
        if (value === '' || value === 'undefined' || value === null)
            return false
        else
            return true
    }

    changeEmail = (value) => {
        let email = value.toLowerCase();
        this.setState({
            emailAddress: email,
            emailValidation: this.checkValue(email) && validateEmail(email) ? true : false
        })
    }

    validateInput = () => {
        const { phoneNumber, emailAddress, name, id, countryCode } = this.state;

        this.props.finishToAddContact(name, emailAddress, phoneNumber, id, countryCode)
        this.props.onCloseModal()
        this.setState({
            id: '',
            name: '',
            phoneNumber: '',
            emailAddress: '',
            emailValidation: false,
            phoneValidation: false,
            nameValidation: false,
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.setState({
                name: this.props.name,
                id: this.props.id,
                phoneNumber: this.props.phone,
                emailAddress: this.props.email,
                emailValidation: true,
                phoneValidation: true,
                nameValidation: true,
            })
        }
    }

    renderBox = (index, tag, type) => {
        return <div className="box-activity-item-modal">
            <img draggable="false" key={index} className="tag" src={tagIcons[tag.index]} />
            <h3 className="box-activity-name">{type === "tag" ? tag.name : tag.attribute}</h3>
            <img src={close} style={{ flex: 2 }} />
        </div>
    }

    renderBoxAttr = (index, tag, type) => {
        return <div className="box-activity-item-modal">
            <img draggable="false" key={index} className="tag" src={attributesIcons[tag.index - 1]} />
            <h3 className="box-activity-name">{type === "tag" ? tag.name : tag.attribute}</h3>
            <img src={close} style={{ flex: 2 }} />
        </div>
    }

    render() {
        const { emailValidation, phoneValidation, nameValidation, name, id, emailAddress, phoneNumber } = this.state;
        const { modalOpened, onCloseModal, onOpenModal, boxItems } = this.props

        let attr = [];
        let tags = [];

        if (!modalOpened) {
            attr = [];
            tags = [];
        }

        if (boxItems) {
            boxItems.map(item => {
                if (item.uid === id) {
                    tags = JSON.parse(JSON.stringify(item.tags));
                    attr = JSON.parse(JSON.stringify(item.attributes));
                }
            })
        }

        const hasTags = this.props.tags && this.props.tags.length > 0

        let renderedTags = hasTags ? tags.map((tag, index) => {
            return this.renderBox(index, tag, tag.type)
        }) : null

        let renderedAttributes = hasTags ? attr.map((item, index) => {
            return this.renderBoxAttr(index, item, item.type)
        }) : null

        let isEdit = id === '' ? false : true;

        if (isEdit) {
            this.props.attribute && this.props.attributes.map(item => {
                item.contacts.map(contact => {
                    if (contact.uid === this.props.id) {
                        attr.push(item);
                    }
                })
            })
        }

        let contactValidation = emailValidation || phoneValidation ? true : false;
        let validation = contactValidation && nameValidation ? true : false
        return (
            <Modal
                styles={ModalStyles}
                open={modalOpened}
                onClose={onCloseModal}
                center
                showCloseIcon={false}>
                <div className='modal-flex-container'>
                    <span className='headerText'>{isEdit ? 'Edit Contact' : 'Add Contact'}</span>
                    <p onClick={onCloseModal} className='times-icon'></p>
                </div>
                {isEdit ? <div className="contacts-listitem-avatar"></div> : null}
                <input
                    onChange={(event) => this.changeName(event.target.value)}
                    style={{ marginTop: '21px' }}
                    className=" input-form modal-input-large"
                    placeholder="Name"
                    value={name}
                    type="text"
                />

                <div className='modal-flex-container' style={{ alignItems: 'center' }}>
                    {/* <input
                        onChange={(event) => this.changePhone(event.target.value)}
                        className=" input-form modal-input modal-input-margins"
                        placeholder="Mobile number*"
                        type="text"
                        value={phoneNumber}
                    /> */}
                    <ReactTelInput
                        defaultCountry="us"
                        flagsImagePath={img}
                        classNames="react-tel-inputssss contact-tel-input"
                        // className="react-tel-inputssss"
                        // placeholder="mobile number"
                        value={this.state.phoneNumber}
                        listStyle={{
                            zIndex: 20,
                            backgroundColor: 'white'
                        }}
                        onChange={this.handleInputChange}
                    // onBlur={handleInputBlur}
                    />

                    <span className='modal-or'>or</span>
                    <input
                        onChange={(event) => this.changeEmail(event.target.value)}
                        className=" input-form modal-input modal-input-margins"
                        placeholder="Email address*"
                        type="text"
                        value={emailAddress}
                    />
                </div>
                {
                    isEdit ?
                        <div>
                            <div style={{ marginTop: 10 }}>
                                <h1 className="box-activity-header">Activities</h1>
                                {renderedTags}
                                <div className="box-activity-item_add">
                                    <img src={add} style={{ marginLeft: 10 }} />
                                    <h3 className="add_header">Add Activity</h3>
                                </div>
                            </div>
                            <div style={{ marginTop: 30 }}>
                                <h1 className="box-activity-header">Attributes</h1>
                                {renderedAttributes}
                                <div className="box-activity-item_add">
                                    <img src={add} style={{ marginLeft: 10 }} />
                                    <h3 className="add_header">Add Attribute</h3>
                                </div>
                            </div>


                        </div>
                        : null
                }

                <div className='modal-flex-container ' style={{ marginTop: '30px', alignItems: 'center' }}>
                    <span className='modal-asterisk-text'>*required fields</span>
                    <div className="buttons-container no-padding">
                        <div className={`rounded-button bcolor-pink ${validation ? 'active' : 'disabled'}`} onClick={validation ? this.validateInput : null}>
                            <span className="rounded-button-text">{isEdit ? 'Save' : 'Add'}</span>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }

}

const ModalStyles = {
    overlay: {
        backgroundColor: 'rgba(216, 216, 216, 0.51)'
    },
    modal: {
        shadowColor: 'rgba(0, 0, 0, 0.24)',
        shadowBlur: '12px',
        shadowOffsetY: '4px',
        borderRadius: '8px',
        paddingTop: '17px',
        paddingRight: '24px',
        paddingLeft: '24px',
        paddingBottom: '24px',
    },
}

export default contactModal