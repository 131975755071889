import _ from 'lodash'

import actionTypes from 'actions/actionTypes'

export const initialState = {

}

export default (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.LOGOUT: {
            return initialState
        }
        case actionTypes.CLEAR_ERROR: {
            // let key
            // if (action.payload) {
            //     key = action.payload
            // }
            // const { key } = action.payload

            // if (key) {
            //     return _.assign({}, state, { key: null })
            // }
            // else {
            //     // Clear all errors
            //     return initialState
            // }

            // Clear all errors
            return initialState
        }

        case actionTypes.ERROR: {
            return _.assign({}, state, action.payload)
        }

        default:
            return state
    }
}