import React, { Component } from 'react'
import { connect } from "react-redux"
import { bindActionCreators } from 'redux'
import loginActions from '../../actions/loginActions'
import { validateEmail } from '../../utils/dataUtils';
import ReactTelInput from 'react-telephone-input';
import '../contactInfo/inputFlagStyle.css';
import img from '../../assets/img/flags.png'

class EditContact extends Component {

  state = {
    email: '',
    phone: '',
    check: false,
    emailEdit: false,
    phoneEdit: false,
    countryCode: '',
  }

  onEmailChange = (event) => {
    let value = event.target.value
    let email = value.toLowerCase();
    this.setState({ email: email })
    if (value !== this.props.emailUni || this.state.phone !== this.props.phone) {
      this.setState({ check: true, emailEdit: true })
    } else {
      this.setState({ check: false, emailEdit: false })
    }
  }

  phoneRegex = phone => phone.replace(/[^+\d]+/g, '');


  handleInputChange = (telNumber, selectedCountry) => {
    let value = telNumber;
    let countryCode = selectedCountry.dialCode;

    let phone = this.phoneRegex(value);

    this.setState({ phone: phone, countryCode: countryCode })


    if (phone !== this.props.phone || this.state.email !== this.props.emailUni) {
      this.setState({ check: true, phoneEdit: true, })
    } else {
      this.setState({ check: false, phoneEdit: false, })
    }
  }


  onPhoneChange = (event) => {
    let value = event.target.value

    let phone = this.phoneRegex(value);

    this.setState({ phone: phone })


    if (value !== this.props.phone || this.state.email !== this.props.emailUni) {
      this.setState({ check: true, phoneEdit: true, })
    } else {
      this.setState({ check: false, phoneEdit: false, })
    }
  }

  componentDidMount() {
    const { phone, emailUni } = this.props;
    this.setState({
      email: emailUni,
      phone: phone,
    })
  }

  saveChanges = async () => {
    const { phoneId,
      emailUniId } = this.props;
    let flag;
    const { email, phone, emailEdit, phoneEdit, countryCode } = this.state;

    let body = {};

    if (emailEdit && phoneEdit) {
      body = {
        "email1": {
          "id": emailUniId,
          "value": email
        },
        "phoneNumber": {
          "id": phoneId,
          "value": phone
        },
        "countryCode": countryCode,
      };
      flag = "both"
    } else if (emailEdit) {
      if (validateEmail(email)) {
        body = {
          "email1": {
            "id": emailUniId,
            "value": email
          },

        }
        flag = "email";
      } else {
        alert("Email is not valid")
      }
    } else if (phoneEdit) {
      body = {
        "phoneNumber": {
          "id": phoneId,
          "value": phone
        },
        "countryCode": countryCode,
      };
      flag = "phone";
    }

    this.props.loginActions.contactInfoChange(body, flag)
    await this.props.history.push('/verify-info')
  }

  cancel = () => {
    this.props.history.push('/verify-info')
  }

  render() {
    const { emailVerify, phoneVerify } = this.props;
    const { check, email, phone } = this.state;

    // let emailVerify = true;
    return (
      <div style={{ width: "60%", margin: '0 auto' }}>
        <h1 style={{ textAlign: 'center' }}>Edit contact information</h1>
        <p style={{ textAlign: 'center' }}>You can make changes to unverified contact details shown here:</p>
        <div style={{ display: 'flex', flexDirection: 'column', width: "361px", margin: '0 auto' }}>
          <div style={{ display: 'flex' }}>
            <input style={{ paddingLeft: '10px', marginBottom: '15px', borderRadius: '8px', border: "solid 1px #e1e1e1", width: "361px", height: "48px" }}
              value={email}
              disabled={emailVerify}
              onChange={event => this.onEmailChange(event)}
            />
            {
              emailVerify ? <div style={{ width: '80px', height: '40px', background: '#00c5ae', marginLeft: '-84px', borderRadius: '5px', marginTop: '4px', display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center', color: 'white' }}>Verified</div> : null
            }

          </div>
          <div style={{ display: 'flex' }}>
            {/* <input style={{ paddingLeft: '10px', borderRadius: '8px', border: "solid 1px #e1e1e1", width: "361px", height: "48px" }}
              value={phone}
              disabled={phoneVerify}
              onChange={event => this.onPhoneChange(event)}

            /> */}
            <ReactTelInput
              defaultCountry="us"
              flagsImagePath={img}
              classNames="react-tel-inputssss edit-tel-input"
              disabled={phoneVerify}
              listStyle={{
                zIndex: 20,
                backgroundColor: 'white'
              }}
              onChange={this.handleInputChange}
              value={phone}
            // onBlur={handleInputBlur}
            />
            {
              phoneVerify ? <div style={{ width: '80px', height: '40px', background: '#00c5ae', marginLeft: '-84px', borderRadius: '5px', marginTop: '4px', display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center', color: 'white', position: 'relative' }}>Verified</div> : null
            }

          </div>

        </div>
        <div style={{ display: 'flex', width: '361px', margin: '20px auto' }}>
          <div className={"rounded-button bcolor-pink margin-vertical15"} style={{
            backgroundColor: '#767676',
            marginRight: "50px"
          }}
            onClick={this.cancel}
          >
            <span className="rounded-button-text">Cancel</span>
          </div>
          <div className={"rounded-button bcolor-pink margin-vertical15" + (check ? '' : ' bcolor-disabled')} onClick={this.saveChanges}>
            <span className="rounded-button-text">Save</span>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  emailUni: state.data.email1.value,
  emailUniId: state.data.email1.id,
  phoneVerify: state.data.phoneVerify,
  emailVerify: state.data.emailVerify,
  phone: state.data.phoneNumber.value,
  phoneId: state.data.phoneNumber.id,
  isVerified: state.data.isVerified,
  error: state.status.contactInfoVerifyError,
})

const mapDispatchToProps = (dispatch) => ({
  loginActions: bindActionCreators(loginActions, dispatch),


})

export default connect(mapStateToProps, mapDispatchToProps)(EditContact)