import React from 'react'
import video_path from '../../assets/video/register_video.mp4'

import './appRedirectStyles.css'

import phone from '../../assets/img/phone/phone.png'

import { Link } from 'react-router-dom'

const AppRedirectView = ({ onStart, onAcceptTos, tosAccepted }) => {
    return <div className="main-container">
        <div id="redirect-container">
            <div id="redirect-title-text" style={{ color: '#161616' }}>Create your Coo-e Beta Account</div>
            <div id="redirect-info-text">We just sent you a text!<br /><br />
                <div id="redirect-info-text" style={{ marginTop: '-25px', marginBottom: '24px' }}>For now, watch this video to learn more about what’s next:</div>
                <div className="container" style={{ marginBottom: '40px' }}>
                    <video className="video" width="640" height="480" controls>
                        <source src={video_path} type="video/mp4" ></source>
                        Your browser does not support the video tag.
                    </video>
                </div >
                Come back to this page after you create an account<br />
                and enter the “Coo-e Research Cental Tab”<br /><br />

                Thanks Again</div>
        </div>

    </div >
}

export default AppRedirectView
