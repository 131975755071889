import React, { Component } from 'react'
import './topLogoStyles.css'

import { connect } from "react-redux"
import { bindActionCreators } from 'redux'
import loginActions from 'actions/loginActions'

import logo from '../../assets/img/tellusIcon/tellusIcon@3x.png'

class TopLogo extends Component {

    logout = () => {
        this.props.loginActions.logout();
        this.props.history.push('/login');
    }

    renderLogout = () => {
        if (this.props.credentials !== null) {
            return (<h3 className='log-out' onClick={this.logout}>Log out</h3>)
        } else {
            return null;
        }
    }

    render() {
        return <div className="main-container">
            <header>
                <img className="header-logo" src={logo} alt="" />
                {this.renderLogout()}
            </header>
        </div >
    }
}

const mapStateToProps = (state) => ({
    credentials: state.user.credentials
})


const mapDispatchToProps = (dispatch) => ({
    loginActions: bindActionCreators(loginActions, dispatch),

})

export default connect(mapStateToProps, mapDispatchToProps)(TopLogo)