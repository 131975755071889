import React, { Component } from 'react'
import './loadingIndicatorStyles.css'

var Spinner = require('react-spinkit')

class LoadingIndicator extends Component {

    render() {
        return (
            <div className="loading-overlay">
                <Spinner name='double-bounce' />
            </div>
        )
    }
}

export default LoadingIndicator