import React from 'react'

import './contactInfo.css'
import './inputFlagStyle.css'
import LoadingIndicator from 'components/ui/loadingIndicator'
import ReactTelInput from 'react-telephone-input';
import '../../../node_modules/react-telephone-input/css/default.css'
import img from '../../assets/img/flags.png'
const ContactInfoView = ({ onNext, onChangeUniEmail, onChangeAltEmail, onChangePhone, isValid, loading, err, handleInputChange, email }) => {
    return <div className="main-container">

        {/* <div id="start-container" onClick={onNext}> */}
        <div id="contact-container">
            <div className="font-demibold-header margin-bottom1">Enter your contact information</div>
            <div className="font-demibold-big color-gray margin-bottom25">We require the following in order to continue:</div>
            <div style={{}}>
                <div>
                    <input className="input-field font-regular-big margin-bottom1" onChange={onChangeUniEmail} type="email" placeholder="University email address" value={email} />
                </div>
                <span style={{ position: 'relative', color: 'tomato', left: '320px', top: '-50px', }}>
                    {err.errorMessage1}</span>
            </div>
            {/* <input className="input-field font-regular-big margin-bottom1" onChange={onChangeAltEmail} type="text" placeholder="Alternate email address" /> */}
            <div>
                {/* <span style={{ position: 'rleative', padding: '10px', marginLeft: '-35px' }}><b>+1</b></span> */}
                {/* <input className="input-field font-regular-big margin-bottom1" onChange={onChangePhone} type="text" placeholder="Mobile number" /> */}
                <ReactTelInput
                    defaultCountry="us"
                    flagsImagePath={img}
                    classNames="react-tel-inputssss"
                    // className="react-tel-inputssss"
                    // placeholder="mobile number"
                    listStyle={{
                        zIndex: 20,
                        backgroundColor: 'white'
                    }}
                    onChange={handleInputChange}
                // onBlur={handleInputBlur}
                />
                {/* <span>{err.errorMessage2}</span> */}
                <span style={{ position: 'relative', color: 'tomato', left: '320px', top: '-40px', width: '100px', marginBottom: '-40px' }}>{err.errorMessage2}</span>

            </div>
            <div className={"rounded-button bcolor-pink margin-vertical15" + (isValid ? '' : ' bcolor-disabled')} onClick={onNext} >
                <span className="rounded-button-text">Next</span>
            </div>
        </div>
        {loading && <LoadingIndicator />}
    </div>
}

export default ContactInfoView
