import React from 'react'

import './loginStyles.css'

import facebook from 'assets/svg/facebook.svg'
import google from 'assets/svg/google.svg'

import { Link } from 'react-router-dom'


const LoginView = ({ onLogin, onLoginFb, onLoginGoogle, onUsernameChanged, onPasswordChanged, active, message, username }) => {
    let isActive = active ? 'active' : 'disabled';
    return <div className="main-container">
        <div id="login-container">
            <div>
                <input
                    onChange={onUsernameChanged}
                    className=" input-form with-input login-input"
                    placeholder="Email address"
                    type="text"
                    vaule={username}
                />
            </div>

            <div>
                <input
                    onChange={onPasswordChanged}
                    className=" input-form with-input login-input"
                    placeholder="Password"
                    type="password"
                />
            </div>

            <div className={`rounded-button rounded-button-email-width ${isActive}`} onClick={onLogin}>
                <span className="login-next rounded-button-text font-demibold-big">Next</span>
            </div>

            <div className={"rounded-button rounded-button-width"} onClick={onLoginFb}>
                <img src={facebook} />
                <span className="rounded-button-text color-black font-demibold-big">Log in with Facebook</span>
                <img src={facebook} style={{ visibility: 'hidden' }} />
            </div>

            <div className={"rounded-button rounded-button-width"} onClick={onLoginGoogle}>
                <img src={google} />
                <span className="rounded-button-text color-black font-demibold-big">Log in with Google</span>
                <img src={google} style={{ visibility: 'hidden' }} />
            </div>
        </div>
    </div>
}

export default LoginView
