import _ from 'lodash'

import actionTypes from '../actions/actionTypes'

export const initialState = {
  data: null,
  success: false,
  teeupsLength: null
}

export default (state = initialState, action) => {

  switch (action.type) {
    case actionTypes.LOGOUT: {
      return _.assign({}, state, { teeupsLength: null, success: false, data: null })
    }
    case actionTypes.GET_RECOMMENDED_TEEUP_SUCCESS: {
      const { activity, contactIds } = action.payload
      const data = {
        name: activity.activityname ? activity.activityname : activity.activityName,
        location: activity.location,
        contactIds
      }

      return _.assign({}, state, { data, success: false, })
    }

    case actionTypes.GET_RECOMMENDED_TEEUP: {
      return _.assign({}, state, { teeupsLength: null, })
    }

    case actionTypes.POST_DRAFTED_TEEUP_SUCCESS: {
      return _.assign({}, state, { data: null, success: true })
    }

    case actionTypes.UPDATE_TEEUP: {
      return _.assign({}, state, { data: action.payload })
    }

    case actionTypes.GET_TEEUP_RESPONSE: {
      const { teeupsLength } = action.payload
      return _.assign({}, state, { teeupsLength: teeupsLength, data: null })
    }

    default:
      return state
  }
}
