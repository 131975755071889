import _ from 'lodash'

import actionTypes from '../actions/actionTypes'

export const initialState = {
    demographics: null,
    email1: {
        id: null,
        value: '',
    },
    // email2: {
    //     id: null,
    //     value: '',
    // },
    phoneNumber: {
        id: null,
        value: '',
    },
    tosAccepted: false,
    isSent: false,
    isVerified: false,
    contacts: [],
    formatedContact: [],
    uid: '',
    activities: [],
    activitySelectors: {},
    languages: [
        'English',
        'Spanish',
        'Chinese',
        'Russian',
        'Portuguese',
    ],
    majors: [
        'Computer Science',
        'Data Science',
        'Information Systems',
        'Information Technology',
    ],
    paymentStatus: "",
    emailVerify: false,
    phoneVerify: false,
    userIdTest: '',
    isSync: false,
    count: 0,
}

export default (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.LOGOUT: {
            return initialState
        }
        case actionTypes.CONTACT_INFO_SET_TOS: {
            return _.assign({}, state, { tosAccepted: action.payload })
        }
        case actionTypes.CLEAR_VERIFY_DATA: {
            let email = {
                id: null,
                value: '',
            }

            let phoneNumber = {
                id: null,
                value: '',
            };

            return _.assign({}, state, { email1: email, phoneNumber: phoneNumber, emailVerify: false, phoneVerify: false, })

        }
        case actionTypes.EMAIL_CHANGE: {
            const { email1 } = action.payload;
            let email = {
                id: email1.id,
                value: email1.value,
            }
            return _.assign({}, state, { email1: email })
        }
        case actionTypes.PHONE_CHANGE: {
            const { phoneNumber } = action.payload;
            let phone = {
                id: phoneNumber.id,
                value: phoneNumber.value,
            }
            return _.assign({}, state, { phoneNumber: phone })
        }
        case actionTypes.SET_FORMATED_CONTACT: {
            return _.assign({}, state, { formatedContact: action.payload })
        }
        case actionTypes.IS_CONTACT_SYNHRONED: {
            return _.assign({}, state, { isSync: action.payload.isContactsSynced })
        }
        case actionTypes.GET_LANGUAGES_END: {
            return _.assign({}, state, { languages: action.payload })
        }

        case actionTypes.DELETED_UID: {
            console.log(action.payload, "uid")
            let a = action.payload
            return _.assign({}, state, { uid: a })
        }

        case actionTypes.PAYMENT_STATUS: {
            return _.assign({}, state, { paymentStatus: action.payload })
        }
        case actionTypes.CLEAR_PAYMENT_STATUS: {
            return _.assign({}, state, { paymentStatus: action.payload })
        }

        case actionTypes.SEND_BASICS_QUESTIONS: {
            return _.assign({}, state, { demographics: action.payload.questions })
        }

        case actionTypes.FETCH_BASICS_QUESTIONS: {
            return _.assign({}, state, { demographics: action.payload })
        }

        case actionTypes.GET_MAJORS_END: {
            return _.assign({}, state, { majors: action.payload })
        }

        case actionTypes.GET_MAJORS_END: {
            return _.assign({}, state, { majors: action.payload })
        }

        case actionTypes.CONTACT_INFO_SEND: {

            let { emailUni, phone, } = action.payload
            let update = {
                email1: {
                    value: emailUni,
                },
                phoneNumber: {
                    value: phone,
                },
            }

            return _.assign({}, state, update)
        }

        case actionTypes.SET_ACTIVITY_SELECTOR: {
            let { type, value } = action.payload
            let activitySelectors = { ...state.activitySelectors }
            activitySelectors[type] = value
            return _.assign({}, state, { activitySelectors })
        }


        case actionTypes.CONTACT_INFO_INIT: {
            return _.assign({}, state, { isSent: false })
        }

        case actionTypes.VERIFY_INFO_INIT: {
            return _.assign({}, state, { isVerified: false })
        }

        case actionTypes.CONTACT_INFO_SENT: {
            let { email1, phoneNumber, userId } = action.payload
            let update = {
                email1: {
                    id: email1.id,
                    value: state.email1.value,
                },
                phoneNumber: {
                    id: phoneNumber.id,
                    value: state.phoneNumber.value,
                },
            }

            return _.assign({}, state, { ...update, isSent: true, userIdTest: userId })
        }

        case actionTypes.REPOPULATE_TEEUPS: {
            const updatedObjects = action.payload

            let activitySelectors = {}

            activitySelectors.campus = updatedObjects.campus && updatedObjects.campus.length > 0 ? 'true' : 'false'
            activitySelectors.classRelated = updatedObjects.classRelated && updatedObjects.classRelated.length > 0 ? 'true' : 'false'
            activitySelectors.work = updatedObjects.work && updatedObjects.work.length > 0 ? 'true' : 'false'
            activitySelectors.interestGroups = updatedObjects.interestGroups && updatedObjects.interestGroups.length > 0 ? 'true' : 'false'

            return _.assign({}, state, { activitySelectors })
        }

        case actionTypes.CONTACT_INFO_VERIFY_SENT: {
            const { flag, res } = action.payload;
            if (flag === "email") {
                return _.assign({}, state, { emailVerify: res })
            } else {
                return _.assign({}, state, { phoneVerify: res })
            }
        }

        case actionTypes.SAVE_CONTACTS: {
            const { contacts } = action.payload
            return _.assign({}, state, { contacts })
        }

        case actionTypes.UPDATE_CONTACTS: {
            const { data, count } = action.payload
            let a = data
            return _.assign({}, state, { formatedContact: a, count: count })

        }

        case actionTypes.SEND_ACTIVITY_QUESTIONS_END: {
            const activities = action.payload
            return _.assign({}, state, { activities })
        }

        default:
            return state
    }
}