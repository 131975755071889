import React, { Component } from 'react'

import ReactDOM from 'react-dom'
import { connect } from "react-redux"
import { bindActionCreators } from 'redux'
import desktopActions from 'actions/desktopActions'
import stepActions from 'actions/stepActions'
import { stepIds } from 'configs/enums'
import { activitiesEndpoints } from 'configs/endpoints'

import IntroductionVideo from './introductionVideo';
import View from './desktopView'

let types = {
    tag: 'tag',
    group: 'group',
    attribute: 'attribute',
    bucket: 'bucket',
    teeup: 'teeup',
    // prevideo: 'prevideo',
    // video: 'video',
}

const formatTag = (activity, index) => {
    return {
        index,
        type: types.tag,
        name: activity.activity ? activity.activity : activity.activityname,
        id: activity.activityId,
    }
}

// let language = 
let attributeQuestions = [
    {
        index: 1,
        type: types.attribute,
        question: 'Select the contacts below who are male.',
        attribute: 'Male',
    },
    {
        index: 2,
        type: types.attribute,
        question: 'Select the contacts below who are female.',
        attribute: 'Female',
    },
    {
        index: 3,
        type: types.attribute,
        question: 'Select the contacts below who are from same hometown as you.',
        attribute: 'Same hometown',
    },
    {
        index: 4,
        type: types.attribute,
        question: 'Select the contacts below who live in the same city as you do now.',
        attribute: 'Same city',
    },
    {
        index: 5,
        type: types.attribute,
        question: 'Select the contacts below who have same primary language as you.',
        attribute: 'Primary language',
    },
    {
        index: 6,
        type: types.attribute,
        question: 'Select the contacts below who know second a language.',
        attribute: 'Secondary language',
    },
    {
        index: 7,
        type: types.attribute,
        question: 'Select the contacts below who has the same major as you.',
        attribute: 'Same major',
    },
    {
        index: 8,
        type: types.attribute,
        question: 'Select the contacts below who would classify as "other" gender.',
        attribute: 'other gender',
    },
]

let buckets = [
    {
        index: 0,
        type: types.bucket,
        question: 'Now tell us how close you are to each of these contactacts. Select a box, then add contacts to that category by clicking the contact on the Social Desktop.',
        groups: [
            {
                id: 1,
                name: 'Extremely close',
            },
            {
                id: 2,
                name: 'Very close',
            },
            {
                id: 3,
                name: 'Moderately close',
            },
            {
                id: 4,
                name: 'Slightly close',
            },
            {
                id: 5,
                name: 'Not at all close',
            },
        ],
    },
    // {
    //     index: 1,
    //     type: types.bucket,
    //     question: 'Select bucket and then tap appropriate contacts.',
    //     groups: [
    //         {
    //             id: 1,
    //             name: 'Extremely close',
    //         },
    //         {
    //             id: 2,
    //             name: 'Very close',
    //         },
    //         {
    //             id: 3,
    //             name: 'Moderately close',
    //         },
    //         {
    //             id: 4,
    //             name: 'Slightly close',
    //         },
    //         {
    //             id: 5,
    //             name: 'Not at all close',
    //         },
    //     ],
    // },
]

let defaultTags = [
    {
        type: types.tag,
        index: 0,
        name: 'Basketball',
        id: 1,
    },
    {
        type: types.tag,
        index: 1,
        name: 'Volleyball',
        id: 2,
    },
    {
        type: types.tag,
        index: 2,
        name: 'Baseball',
        id: 3,
    },
]

const teeupCreation = [
    {
        index: 1,
        type: types.teeup,
        question: 'Think of a group activity (3+ people) that you need to do with some of the contacts you have placed on the social desktop below in the next week or so.  We would like to pay you to coordinate that activity using a TeeUp as we demonstrated in the video. To do that please right-click on a group or activity icon and then select create TeeUp.',
    }
]

const createPages = (tags) => {
    let pages = [
        ...tags,
        { type: types.group },
        ...attributeQuestions,
        ...buckets,
        ...teeupCreation,
    ]
    return pages
}

class DesktopContainer extends Component {

    constructor(props) {
        super(props)



        let tags = props.activities && props.activities.length > 0 ? props.activities.map((activity, index) => {
            return formatTag(activity, index)
        }) : []
        props.stepActions.setActiveStep(stepIds.contacts)
        // 7 8

        let pages = createPages(tags)

        let primaryLanguage = props.demographics.primaryLanguage;
        let secoundaryLanguage = props.demographics.otherLanguages ? props.demographics.otherLanguages[0] : null;

        let question1 = 'Select the contacts below who have same primary language as you.';
        let question2 = "Select the contacts below who know second a language.";

        if (primaryLanguage === 'English') {
            if (secoundaryLanguage === undefined) {
                // nema pitanja o jeziku
                pages.map((item, key) => {

                    if (item.question === question1) {
                        pages.splice(key, 1)
                        pages.splice(key, 1)

                    }

                })

            } else {
                // postavljam samo pitanje o secoundary language

                pages.map((item, key) => {
                    if (item.question === question1) {
                        pages.splice(key, 1)
                    }
                })
                pages.map((item, key) => {

                    if (item.question === question2) {
                        item.question = `select contact who speaks ` + secoundaryLanguage;
                        item.attribute = `${secoundaryLanguage} language`
                    }
                })

            }
        } else if (primaryLanguage !== "English") {
            if (secoundaryLanguage === undefined) {
                // pitam ko prica taj primary language
                pages.map((item, key) => {
                    if (item.question === question2) {
                        pages.splice(key, 1)
                    }
                    if (item.question == question1) {
                        item.question = `select contact who also speaks ` + primaryLanguage
                        item.attribute = `${primaryLanguage} language`
                    }
                })
            } else {
                if (secoundaryLanguage === "English") {
                    pages.map((item, key) => {
                        if (item.question == question1) {
                            item.question = `select contact who also speaks ` + primaryLanguage
                            item.attribute = `${primaryLanguage} language`
                        }
                        if (item.question == question2) {
                            pages.splice(key, 1)
                        }

                    })

                } else {
                    pages.map(item => {
                        if (item.question === question1) {
                            item.question = `select contact who speaks ` + primaryLanguage
                            item.attribute = `${primaryLanguage} language`

                        }
                        if (item.question === question2) {
                            item.question = `select contact who speaks ` + secoundaryLanguage;
                            item.attribute = `${secoundaryLanguage} language`


                        }
                    })
                    pages = pages;
                }
            }
        }


        let state = {
            width: -1,
            height: -1,

            current: 0,
            pages,
            tags: tags,
            attributes: [], // index, attribute, question
            groups: [],
            bucketsGroups: {},
            bucketsGroupsSelectedContacts: {},

            activeDrags: 0,
            deltaPosition: {
                x: 0,
                y: 0,
            },
            controlledPosition: {
                x: 0,
                y: 0,
            },
            stepGroup: true,
            maxPage: pages.length, // tags + groups page
            showCreateTeeup: false,
            activeBucketUid: null,

            setContextSelectedTeeupId: '',
            teeupInformation: {
                contacts: []
            },
            modalOpened: false,
            teeupContacts: [],
            boxItems: [],
            contacts: [],
            videoActive: true,
            bucketNextBtn: false,
            newTeeup: false,
            count: 0,
            formatedContact: [],
            uid: '1',
            newAttributes: [],
        }

        let attrs = [];
        let persistedState = this.props.desktopState
        if (persistedState.pages) {

            state = { ...state, ...persistedState }

            console.log('sattr')

            if (props.activities && props.activities.length > 0) {
                state.tags = tags
                state.pages = pages
            }
        }
        console.log(state.attributes, "ast")
        pages.map(item => {
            if (item.type === "attribute") {
                attrs.push(item)
            }
        })

        if (state.attributes) {
            state.attributes.map(attr => {
                if (attr.contacts && attr.contacts.length) {
                    attrs.map(attrs => {
                        if (attrs.attribute === attr.attribute) {
                            attrs.contacts = attr.contacts
                        }
                    })
                }
            })
        }


        state.currentPage = 0
        state.selectedTabIndex = 0
        state.attributes = attrs;

        this.state = state
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.teeupCreated && nextProps.teeupCreated) {
            this.props.history.push('/last')
        }
        if (!this.props.teeup && nextProps.teeup) {
            const { contactIds } = nextProps.teeup
            const teeupContacts = nextProps.contacts.filter(({ uid }) => contactIds.includes(uid))
            this.setState({ teeupContacts })
        }
    }

    onOpenModal = () => {
        this.setState({ modalOpened: true });

    };

    onCloseModal = () => {
        this.setState({ modalOpened: false });
    };


    addContacts = () => {
        this.onOpenModal()
    }

    updateContactList = (uid) => {

        let a = JSON.parse(JSON.stringify(this.props.formatedContact))
        a.map(item => {
            if (item.uid === uid) {
                item = { ...item, draggable: true }
            }
        })

        let group = JSON.parse(JSON.stringify(this.state.groups))

        this.setState({ formatedContact: a, uid: uid, groups: group })

    }

    finishToAddContact = (name, email, phone, id, countryCode) => {
        if (id !== '') {
            // this.props.desktopActions.editContact(name, id, countryCode);
            console.log("edit")
        } else {
            this.props.desktopActions.addContacts(name, email, phone, countryCode)
        }

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.activities && this.props.activities.length > 0 && prevProps.activities != this.props.activities) {
            let tags = this.props.activities.map((activity, index) => {
                return formatTag(activity, index)
            })

            let pages = createPages(tags)
            this.setState({ tags, pages })
        }

        if (this.state.count !== this.props.count) {
            this.setState({
                contact: this.props.contact,
                count: this.props.count
            })
        }

        if (prevProps.formatedContact.length !== this.props.formatedContact.length) {
            let contacts = JSON.parse(JSON.stringify(this.props.formatedContact))
            this.setState({ formatedContact: contacts })
        }
    }

    componentDidMount() {
        let fc = JSON.parse(JSON.stringify(this.props.formatedContact))
        this.setState({ showCreateTeeup: false, formatedContact: fc, current: 0, videoActive: true, stepGroup: true })
    }

    handleDrag = (e, ui) => {
        const { x, y } = this.state.deltaPosition;
        this.setState({
            deltaPosition: {
                x: x + ui.deltaX,
                y: y + ui.deltaY,
            }
        })
    }

    onTabSelected = (index) => {
        if (this.state.selectedTabIndex != index) {
            this.setState({ selectedTabIndex: index })
        }
    }

    onStart = () => {
        this.setState({ activeDrags: ++this.state.activeDrags });
    }

    onStop = () => {
        this.setState({ activeDrags: --this.state.activeDrags });
    }

    // For controlled component
    adjustXPos = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { x, y } = this.state.controlledPosition;
        this.setState({ controlledPosition: { x: x - 10, y } });
    }

    adjustYPos = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { controlledPosition } = this.state;
        const { x, y } = controlledPosition;
        this.setState({ controlledPosition: { x, y: y - 10 } });
    }

    onControlledDrag = (e, position) => {
        const { x, y } = position;
        this.setState({ controlledPosition: { x, y } });
    }

    onControlledDragStop = (e, position) => {
        this.onControlledDrag(e, position);
        this.onStop();
    }

    onNext = () => {

        let { pages, currentPage, groups, tags, attributes, bucketsGroups } = this.state
        this.props.desktopActions.saveState(this.state)
        this.props.desktopActions.postDesktopState(this.state)

        currentPage = currentPage + 1

        this.setState({ current: currentPage })

        if (currentPage >= pages.length) {
            this.props.history.push('/last')
        }

        let updates = {
            currentPage
        }

        let nextType = pages[currentPage] ? pages[currentPage].type : null
        let previousType = pages[currentPage - 1].type

        this.updateStepIndicator(nextType)

        if (nextType == types.group && previousType == types.tag) {
            // Past tags (activities) - now groups

            let activityContacts = this.refBoxDesktop.getItems()
            let activityGroups = {}
            tags.forEach(tag => {
                activityGroups[tag.id] = {
                    activityId: tag.id,
                    members: [],
                }
            })

            activityContacts.forEach(contact => {
                if (contact.tags) {
                    contact.tags.forEach(tag => {
                        let activityGroup = activityGroups[tag.id]
                        if (activityGroup) {
                            activityGroup.members.push(contact.uid)
                        }
                    })
                }
            })

            let activityGroupsArray = Object.keys(activityGroups).map(key => activityGroups[key])
            this.props.desktopActions.sendActivityGroups(activityGroupsArray)
        }
        else if (nextType == types.attribute && previousType == types.group) {
            // Past groups - now attributes
            this.props.desktopActions.sendContactGroups(groups)
        }
        else if (nextType == types.bucket && previousType == types.attribute) {
            // Past attributes - now buckets
            this.props.desktopActions.sendAttributeGroups(attributes)
        }
        else if (!nextType && previousType == types.bucket) {
            // Last page, past buckets - now finals
            this.props.desktopActions.sendBucketGroups(bucketsGroups)
        }

        if (currentPage == tags.length) {
            updates.selectedTabIndex = 1;
        }

        this.setState(updates)
        this.checkBucketGroup(this.state.bucketsGroups)
        this.props.desktopActions.updateContacts(this.state.formatedContact, 1)
    }

    onPrev = () => {
        let { currentPage, pages } = this.state

        if (currentPage == 0) {
            const rootUrl = '/survey/activities/quiz'
            this.props.history.push('/survey/activities/addinterestsList')
        }

        currentPage -= 1

        let updates = {
            currentPage
        }

        if (currentPage < this.state.tags.length) {
            updates.selectedTabIndex = 0
        }

        let prevType = pages[currentPage] ? pages[currentPage].type : null
        this.updateStepIndicator(prevType)

        // this.props.desktopActions.formatContact()
        this.setState(updates)
        this.props.desktopActions.updateContacts(this.state.formatedContact, 1)

    }

    updateStepIndicator = (type) => {
        switch (type) {
            case types.tag: {
                this.props.stepActions.setActiveStep(stepIds.contacts)
                this.setState({ stepGroup: true })

                break
            }
            case types.group: {
                this.props.stepActions.setActiveStep(stepIds.grouping)
                this.setState({ stepGroup: true })
                break
            }
            case types.attribute: {
                this.props.stepActions.setActiveStep(stepIds.rating)
                this.setState({ stepGroup: true })

                break
            }
            case types.bucket: {
                this.props.stepActions.setActiveStep(stepIds.rating)
                this.setState({ stepGroup: false })

                break
            }
            case types.teeup: {
                this.props.stepActions.setActiveStep(stepIds.teeup)
                this.setState({ stepGroup: true })

                break;
            }
            case types.prevideo: {
                this.props.stepActions.setActiveStep(stepIds.teeup)
                this.setState({ stepGroup: true })

                break
            }
            case types.video: {
                this.props.stepActions.setActiveStep(stepIds.teeup)
                this.setState({ stepGroup: true })

                break
            }
            default: {
                this.props.stepActions.setActiveStep(stepIds.activities)
                this.setState({ stepGroup: true })
                break
            }
        }
    }

    onCreateTeeup = (item) => {
        const { boxItems } = this.state
        let contactIds = []
        switch (item.type) {
            case 'attribute':
                contactIds = item.contacts ? item.contacts.map(({ uid }) => uid) : null
                break;
            case 'group':
                contactIds = Object.keys(item.lines)
                break;
            case 'tag':
                contactIds = boxItems.filter(({ tags }) => tags.filter(({ id }) => id === item.id).length > 0).map(({ uid }) => uid)
                break;
            default:
        }
        this.props.desktopActions.getRecommendedTeeUp(contactIds)
    }

    deleteGroup = (group) => {
        let filteredGroups = this.state.groups.filter(singleGroup => singleGroup.uid !== group.uid)
        this.setState({
            groups: filteredGroups
        })
        this.props.desktopActions.deleteGroup(group)
    }

    onSubmitTeeUp = (obj) => {
        const { name, location, inviteMessage, contactIds } = this.props.teeup
        console.log(this.props.teeup, "teeup")
        this.props.desktopActions.postDraftedTeeUp(name, location, inviteMessage, contactIds, obj)
        this.props.history.push('/teeup')
    }

    onUpdateTeeUp = (teeup) => {
        this.props.desktopActions.updateTeeUp(teeup)
    }

    onUpdateBoxItems = (boxItems) => {
        this.setState({ boxItems })
    }

    onGroupsModified = (groups) => {
        this.setState({ groups })
    }

    checkAttribute = (index, contactItem) => {


    }

    onAttribute = (attributeQuestion, contactItem) => {
        let attributes = [...this.state.attributes]

        let attribute
        let attributeIndex

        attributes = attributes.map((element, index) => {
            if (element.attribute == attributeQuestion.attribute) {
                element = { ...element }
                // Attribute already exists

                let foundContact = false
                if (element.contacts) {
                    element.contacts = [...element.contacts]
                    element.contacts.forEach((contact, index) => {
                        if (contact.uid == contactItem.uid) {
                            foundContact = true

                            element.contacts.splice(index, 1)
                        }
                    })

                    if (!foundContact) {
                        element.contacts.push(contactItem)
                    }


                } else {
                    let a = [];
                    a.push(contactItem)
                    element = { ...element, contacts: a }
                }


            }

            attribute = element
            attributeIndex = index
            return element
        })

        console.log(attribute, "ATTRIBUTE")

        let newAttributes = { ...attributeQuestion }
        if (!attribute) {
            // New attribute

            console.log(attribute, "attribute aaaaa")
            newAttributes.contacts = [contactItem]
            attributes.push(newAttributes)
            console.log("uso bbb")

        }
        else {
            if (attribute.contacts && attribute.contacts.length == 0) {
                // Removed attribute
                attributes.splice(attributeIndex, 1)
                console.log("uso aaaa")

            }
        }

        this.setState({ attributes })
        console.log(attributes, "attributess")
    }

    onBucketSelected = (groupId) => {
        if (this.state.activeBucketUid == groupId) {
            this.setState({ activeBucketUid: null })
        }
        else {
            this.setState({ activeBucketUid: groupId })
        }
    }

    onRemoveBucketContact = (bucketId, groupId, contactUid) => {
        let bucketsGroups = { ...this.state.bucketsGroups }
        let bucketGroups = { ...bucketsGroups[bucketId] }
        bucketsGroups[bucketId] = bucketGroups

        let contactsGroup = [...bucketGroups[groupId]]

        let bucketsGroupsSelectedContacts = { ...this.state.bucketsGroupsSelectedContacts }
        let selectedContacts = bucketsGroupsSelectedContacts[bucketId] ? bucketsGroupsSelectedContacts[bucketId] : {}
        bucketsGroupsSelectedContacts[bucketId] = selectedContacts

        delete selectedContacts[contactUid]

        bucketGroups[groupId] = contactsGroup
        for (let i = 0, len = contactsGroup.length; i < len; i++) {
            if (contactsGroup[i].uid == contactUid) {
                contactsGroup.splice(i, 1)
                break
            }
        }

        this.setState({ bucketsGroups, bucketsGroupsSelectedContacts })

        this.checkBucketGroup(bucketsGroups)
    }

    onContactTapped = (contact) => {
        let { name, uid, image } = contact
        let { activeBucketUid, currentPage, bucketsGroups, bucketsGroupsSelectedContacts, pages } = this.state


        let page = pages[currentPage]
        let pageType = page.type
        let bucket = pageType === types.bucket ? page : null

        if (bucket && activeBucketUid != null) {

            // Mark selected contacts
            bucketsGroupsSelectedContacts = { ...bucketsGroupsSelectedContacts }
            let selectedContacts = bucketsGroupsSelectedContacts[bucket.index] ? bucketsGroupsSelectedContacts[bucket.index] : {}
            bucketsGroupsSelectedContacts[bucket.index] = selectedContacts
            if (!selectedContacts[contact.uid]) {
                // Contact not yet selected
                selectedContacts[contact.uid] = true

                bucketsGroups = { ...bucketsGroups }
                let groups = bucketsGroups[bucket.index] ? { ...bucketsGroups[bucket.index] } : {}
                bucketsGroups[bucket.index] = groups

                let bucketGroup = groups[activeBucketUid] ? [...groups[activeBucketUid]] : []
                groups[activeBucketUid] = bucketGroup

                bucketGroup.push({ name, uid, image })

                this.setState({ bucketsGroups, bucketsGroupsSelectedContacts })
            }
        }


        this.checkBucketGroup(bucketsGroups)


    }

    closeVideo = () => {
        this.setState({ videoActive: false })
    }

    onContactDrop = (position, desktopViewRef, itemWidth = 100, itemHeight = 100) => {
        let desktopRect = ReactDOM.findDOMNode(desktopViewRef)
            .getBoundingClientRect()

        let rect = ReactDOM.findDOMNode(this.boxesRef)
            .getBoundingClientRect()

        let itemOffsetX = position.x + itemWidth
        let itemOffsetY = position.y + itemHeight

        if (itemOffsetX > desktopRect.width) {
            let width = (this.state.width > 0 ? this.state.width : rect.width) + itemWidth
            this.setState({ width })
        }

        if (itemOffsetY > desktopRect.height) {
            let height = (this.state.height > 0 ? this.state.height : rect.height) + itemHeight
            this.setState({ height })
        }
    }

    resizeDesktop = (event, delta) => {
        let rect = ReactDOM.findDOMNode(this.boxesRef)
            .getBoundingClientRect()
        let width = (rect.width + delta.width)
        let height = rect.height + delta.height
        this.setState({ height, width })

    }

    setContextSelectedTeeup = (groupId) => {
        this.setState({
            setContextSelectedTeeupId: groupId,
        })
    }

    waitingPage = () => {
        this.props.history.push('/teeup')
    }

    checkBucketGroup = (bucketsGroups) => {
        let check = true;
        let bucketContacts = [];

        for (let bucket in bucketsGroups) {
            for (let key in bucketsGroups[bucket]) {
                bucketsGroups[bucket][key].map(item => {
                    bucketContacts = [...bucketContacts, item]
                })
            }
        }

        console.log(bucketContacts, 'buccketContact')

        if (bucketContacts.length === this.state.boxItems.length) {
            check = true;
        } else {
            check = false;
        }

        this.setState({ bucketNextBtn: check })
    }

    findInBuckets = (uid) => {
        console.log(this.state.bucketsGroups[0], "bucketGroups")
        let bg = JSON.parse(JSON.stringify(this.state.bucketsGroups[0]))
        let bgSelectecContact = JSON.parse(JSON.stringify(this.state.bucketsGroupsSelectedContacts[0]))
        let id = "";
        console.log(bgSelectecContact, "asdsad")

        for (let key in bg) {
            bg[key].map(it => {
                if (it.uid === uid) {
                    id = uid
                    console.log("ULAZI")
                    bg[key].splice(it, 1);
                } else {
                    console.log("a")
                }
            })
        }
        for (let contactID in bgSelectecContact) {
            console.log("asdasd", contactID, id)
            if (contactID === id) {
                console.log(id, "dasdasd")
                delete bgSelectecContact[contactID]

            }
        }


        let a = { 0: bg }
        let b = { 0: bgSelectecContact }
        this.setState({
            bucketsGroups: a,
            bucketsGroupsSelectedContacts: b
        })

    }

    render() {
        const dragHandlers = { onStart: this.onStart, onStop: this.onStop }
        const { tags, attributes, groups, deltaPosition, controlledPosition, activeDrags, currentPage, maxPage, selectedTabIndex,
            showCreateTeeup, activeBucketUid, bucketsGroups, bucketsGroupsSelectedContacts, pages, teeupInformation, width, height, modalOpened, teeupContacts, boxItems, stepGroup, videoActive, current, bucketNextBtn, formatedContact, uid, newAttributes } = this.state

        let page = pages[currentPage] ? pages[currentPage] : {}
        let pageType = page.type;
        let attributeQuestion = pageType === types.attribute ? page : null
        let tag = pageType === types.tag ? page : null
        let bucket = pageType === types.bucket ? page : null
        // Show tabs when there are no more tags to complete
        console.log(bucketsGroups, "page")
        let showTabs = pageType === types.tag ? false : true
        // let showTabs = true
        let showCreateGroup = pageType === types.group ? true : false
        const { teeup } = this.props

        let lastPageIndex = pages && pages.length > 0 ? pages.length - 1 : 0

        let updatePage = 0;

        if (pageType === "tag") {
            updatePage = tag.index
        } else if (pageType === "group") {
            updatePage = 40;
        } else if (pageType === "attribute") {
            updatePage = attributeQuestion.index
        } else if (pageType === "bucket") {
            updatePage = bucket.index
        }


        return (

            videoActive ?
                <IntroductionVideo closeVideo={this.closeVideo} />
                :
                <View
                    updatePage={updatePage}
                    newAttributes={newAttributes}
                    dragHandlers={dragHandlers}
                    deltaPosition={deltaPosition}
                    controlledPosition={controlledPosition}
                    activeDrags={activeDrags}
                    handleDrag={this.handleDrag}
                    adjustXPos={this.adjustXPos}
                    adjustYPos={this.adjustYPos}
                    onControlledDrag={this.onControlledDrag}
                    onControlledDragStop={this.onControlledDragStop}
                    current={current}
                    // onPrev={currentPage > 0 ? this.onPrev : undefined}
                    onPrev={this.onPrev}
                    // onNext={currentPage >= maxPage ? undefined : this.onNext}
                    onNext={currentPage != lastPageIndex ? this.onNext : undefined}
                    findInBuckets={this.findInBuckets}
                    tag={tag}
                    attributeQuestion={attributeQuestion}
                    bucket={bucket}
                    bucketGroups={bucket && bucketsGroups[bucket.index] ? bucketsGroups[bucket.index] : {}}
                    bucketSelectedContacts={bucket && bucketsGroupsSelectedContacts[bucket.index] ? bucketsGroupsSelectedContacts[bucket.index] : {}}
                    onBucketSelected={this.onBucketSelected}
                    activeBucketUid={activeBucketUid}
                    onContactTapped={this.onContactTapped}
                    onRemoveBucketContact={this.onRemoveBucketContact}
                    uid={this.props.uid} // UIDDDDD
                    teeup={teeup}
                    teeupContacts={teeupContacts}
                    onUpdateTeeUp={this.onUpdateTeeUp}
                    boxItems={boxItems}
                    onUpdateBoxItems={this.onUpdateBoxItems}

                    selectedTabIndex={selectedTabIndex}
                    onTabSelected={this.onTabSelected}
                    showTabs={showTabs}
                    showCreateGroup={showCreateGroup}
                    onCreateTeeup={this.onCreateTeeup}
                    onSubmitTeeUp={this.onSubmitTeeUp}
                    showCreateTeeup={showCreateTeeup}
                    deleteGroup={this.deleteGroup}

                    onGroupsModified={this.onGroupsModified}
                    onAttribute={this.onAttribute}
                    uid={uid}
                    stepGroup={stepGroup}
                    tags={tags}
                    attributes={attributes}
                    groups={groups}
                    contacts={formatedContact}
                    pageType={pageType}
                    setContextSelectedTeeup={this.setContextSelectedTeeup}
                    teeupInformation={teeupInformation}

                    width={width > 0 ? width : null}
                    height={height > 0 ? height : null}
                    onContactDrop={this.onContactDrop}
                    boxesRef={ref => { this.boxesRef = ref }}
                    refBoxDesktop={ref => this.refBoxDesktop = ref}
                    addContacts={this.addContacts}
                    deletedUid={this.props.desktopActions.deletedUid}
                    contactData={formatedContact}

                    //modal
                    modalOpened={modalOpened}
                    onOpenModal={this.onOpenModal}
                    onCloseModal={this.onCloseModal}
                    finishToAddContact={this.finishToAddContact}

                    //resize
                    resizeDesktop={this.resizeDesktop}

                    // TEMPRARY FOR TEST 
                    waitingPage={this.waitingPage}
                    bucketNextBtn={bucketNextBtn}
                    updateContactList={this.updateContactList}

                />
        )
    }
}

const mapStateToProps = (state) => ({
    activities: state.data.activities,
    contacts: state.data.contacts,
    uid: state.data.uid,
    teeup: state.teeup.data,
    teeupCreated: state.teeup.success,
    desktopState: state.desktop,
    formatedContact: state.data.formatedContact,
    demographics: state.data.demographics
})

const mapDispatchToProps = (dispatch) => ({
    desktopActions: bindActionCreators(desktopActions, dispatch),
    stepActions: bindActionCreators(stepActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(DesktopContainer)