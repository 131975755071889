import _ from 'lodash'

import actionTypes from 'actions/actionTypes'
import { loginProviders } from 'configs/enums'

export const initialState = {
    credentials: null,
    userInfo: null,
}

export default (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.AUTH_FB:
        case actionTypes.AUTH_GOOGLE:
        case actionTypes.LOGIN_EMAIL:
        case actionTypes.CLEAR_USER:

        case actionTypes.LOGOUT:
            return _.assign({}, state, { credentials: null, userInfo: null, error: null })

        case actionTypes.LOGIN_FB:
            return _.assign({}, state, { credentials: null, userInfo: null, error: null, connection: loginProviders.fb })

        case actionTypes.LOGIN_GOOGLE:
            return _.assign({}, state, { credentials: null, userInfo: null, error: null, connection: loginProviders.google })

        case actionTypes.REGISTER_END:
        case actionTypes.LOGIN_END: {
            const { userInfo, credentials } = action.payload
            let update = { userInfo }
            if (credentials) {
                update.credentials = credentials
            }
            return _.assign({}, state, update)
        }

        case actionTypes.LOGIN_USER_UPDATE: {
            let keyValuePair = action.payload

            let userInfo = _.assign({}, state.userInfo, keyValuePair)
            return _.assign({}, state, { userInfo })
        }

        case actionTypes.AUTH_END:
            const { credentials, userInfo, error } = action.payload
            console.log(credentials, userInfo, error, "crd, userInfo, error")
            if (credentials && userInfo) {
                // Logged in
                return _.assign({}, state, { credentials, userInfo, error: null })
            }
            else {
                return _.assign({}, state, { error })
                // return _.assign({}, state, { credentials: null, userInfo: null })
            }

        default:
            return state;
    }
}