import React, { Component } from 'react'
import { Modal, Input } from 'antd'
import Tooltip from 'react-tooltip-lite';

import close from 'assets/svg/close.svg'
import when from 'assets/svg/when.svg'
import where from 'assets/svg/where.svg'

const toolbarSteps = {
    1: {
        header: 'Here’s what we came up with…',
        content: 'This is our best guess for creating a TeeUp based on your selection criteria. Help us improve by answering a few quick questions.',
    },
    2: {
        content: 'Does this name match the intended activity?',
    },
    3: {
        content: 'Do you want to remove any people from this list?'
    },
    4: {
        content: 'Do you want to add any people to this list?'
    },
    5: {
        content: 'Is this location exactly where you want to do the activity?'
    },
    6: {
        content: 'Is this location specific enough to carry out the activity?'
    },
    7: {
        content: 'Is this location near to where you want to do the activity?'
    },
    8: {
        content: 'Thank you for your feedback.',
        content2: 'Once you send this draft TeeUp to the app, you will be able to edit it and earn money coordinating.'
    }
}

const toolbarSetting = {
    direction: "right",
    tagName: "span",
    distance: 16,
    padding: 16,
    forceDirection: true
}

class CreateTeeupModal extends Component {

    renderRadioSelection = (intendedName, nameLiteral, contentNumber) => {

        return <div className='toolbar'>
            <p className='toolbar-content'>{toolbarSteps[contentNumber].content}</p>
            <div className="input-form-radio">
                <label className="input-radio-label">
                    <input name={nameLiteral} value={true}
                        className="radio-input"
                        onChange={(event) => this.writeValue(event, nameLiteral)}
                        type="radio" /> Yes </label>
                <label className="input-radio-label">
                    <input name={nameLiteral} value={false}
                        className="radio-input"
                        onChange={(event) => this.writeValue(event, nameLiteral)}
                        type="radio" /> No </label>
            </div>
            <div className={intendedName ? 'tooltip-button' : ' tooltip-button-inactive'}>
                <div className={'tooltip-button-text '} onClick={() => this.incrementStep(intendedName)}>
                    Next
                </div>
            </div>
        </div>;
    }

    state = {
        activeStep: 1,
        intendedName: null,
        wantToRemovePeople: null,
        wantToAddPeople: null,
        exactLocation: null,
        specificLocation: null,
        nearLocation: null,
    }

    incrementStep = (value) => {
        if (value) {
            this.setState({
                activeStep: this.state.activeStep + 1,
            })
        }

    }

    writeValue = (event, param) => {
        this.setState({
            [param]: event.target == undefined ? '' : event.target.value
        })
    }

    render() {

        const { teeup, teeupContacts, onUpdateTeeUp, onSubmitTeeUp } = this.props;
        const { activeStep, intendedName, wantToRemovePeople, wantToAddPeople, exactLocation, specificLocation, nearLocation } = this.state

        let obj = { intendedName, wantToRemovePeople, wantToAddPeople, exactLocation, specificLocation, nearLocation };

        return (
            <Modal
                visible={!!teeup}
                closable={false}
                centered
                title={(
                    <Tooltip
                        {...toolbarSetting}
                        isOpen={!!teeup && activeStep === 1}
                        distance={48}
                        content={(
                            <div className='firstToolbar toolbar'>
                                <p className='toolbar-header'>{toolbarSteps["1"].header}</p>
                                <p className='toolbar-content'>{toolbarSteps["1"].content}</p>
                                <div className='tooltip-button'>
                                    <div className='tooltip-button-text' onClick={() => this.incrementStep(true)}>
                                        Next
                                    </div>
                                </div>
                            </div>
                        )}>
                        <div className="ant-modal-title-text">
                            Create a TeeUp
                            <img
                                className="ant-modal-close-button"
                                src={close}
                                onClick={() => onUpdateTeeUp(null)}
                            />
                        </div>
                    </Tooltip>
                )}
                footer={[

                    <Tooltip
                        {...toolbarSetting}
                        isOpen={!!teeup && activeStep === 8}
                        content={(
                            <div className='firstToolbar toolbar'>
                                <p className='toolbar-header'>{toolbarSteps[8].content}</p>
                                <p className='toolbar-content'>{toolbarSteps[8].content2}</p>
                            </div>
                        )}>
                        <div
                            key="submit"
                            style={{ width: '100%' }}
                            className={!(activeStep === 8) ? " inactive-button" : "rounded-button bcolor-pink "}
                            onClick={activeStep === 8 ? () => onSubmitTeeUp(obj) : ''}
                        >
                            <span className={!(activeStep === 8) ? " rounded-button-text-grey" : "rounded-button-text "}>Send to coo-e app</span>
                        </div>
                    </Tooltip>

                ]}
            >
                <div className="label-wrapper">
                    <div className="primary-text">Basic Information</div>
                </div>
                <Tooltip
                    {...toolbarSetting}
                    isOpen={!!teeup && activeStep === 2}
                    content={(
                        this.renderRadioSelection(intendedName, 'intendedName', 2)
                    )}>
                    <div className="input-wrapper">
                        <Input
                            placeholder="Name"
                            value={teeup ? teeup.name : ''}
                            onChange={e => onUpdateTeeUp({ ...teeup, name: e.target.value })}
                        />
                    </div>
                </Tooltip>

                {
                    activeStep !== 4
                    &&
                    <Tooltip
                        {...toolbarSetting}
                        isOpen={!!teeup && activeStep === 3}
                        content={this.renderRadioSelection(wantToRemovePeople, 'wantToRemovePeople', 3)

                        }>
                        <div className="contacts-container">
                            <div className="contacts-num-label">{`${teeupContacts.length} people`}</div>
                            <div className="contacts-list">
                                {teeupContacts.map(({ uid, firstName }) => (
                                    <div key={uid} className="contacts-listitem">
                                        <div className="contacts-listitem-avatar">
                                            {/* <img /> */}
                                        </div>
                                        <div className="contacts-listitem-name">{firstName}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Tooltip>
                }
                {activeStep === 4
                    &&
                    <Tooltip
                        {...toolbarSetting}
                        isOpen={!!teeup && activeStep === 4}
                        content={this.renderRadioSelection(wantToAddPeople, 'wantToAddPeople', 4)
                        }>
                        <div className="contacts-container">
                            <div className="contacts-num-label">{`${teeupContacts.length} people`}</div>
                            <div className="contacts-list">
                                {teeupContacts.map(({ uid, firstName }) => (
                                    <div key={uid} className="contacts-listitem">
                                        <div className="contacts-listitem-avatar">
                                            {/* <img /> */}
                                        </div>
                                        <div className="contacts-listitem-name">{firstName}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Tooltip>
                }


                <div className="label-wrapper">
                    <div className="primary-text">Invitation Message</div>
                </div>
                <div className="input-wrapper">
                    <Input
                        placeholder="Add a message to your invitees"
                        value={teeup ? teeup.inviteMessage : ''}
                        onChange={e => onUpdateTeeUp({ ...teeup, inviteMessage: e.target.value })}
                    />
                </div>

                <div className="label-wrapper">
                    <div className="secondary-text">You can include an optional message to describe your TeeUp a bit
                        more to your invitees.
                    </div>
                    <div className="primary-text">Game Plan</div>
                </div>
                <div className="input-wrapper">
                    <img src={when} />
                    <Input
                        placeholder="Suggest When"
                    />
                </div>

                {activeStep !== 6 && activeStep !== 7
                    &&
                    <Tooltip
                        {...toolbarSetting}
                        isOpen={!!teeup && activeStep === 5}
                        content={this.renderRadioSelection(exactLocation, 'exactLocation', 5)
                        }>
                        <div className="input-wrapper">
                            <img src={where} />
                            <Input
                                placeholder="Suggest Where"
                                value={teeup ? teeup.location : ''}
                                onChange={e => onUpdateTeeUp({ ...teeup, location: e.target.value })}
                            />
                        </div>
                    </Tooltip>
                }

                {activeStep === 6
                    &&
                    <Tooltip
                        {...toolbarSetting}
                        isOpen={!!teeup && activeStep === 6}
                        content={this.renderRadioSelection(specificLocation, 'specificLocation', 6)
                        }>
                        <div className="input-wrapper">
                            <img src={where} />
                            <Input
                                placeholder="Suggest Where"
                                value={teeup ? teeup.location : ''}
                                onChange={e => onUpdateTeeUp({ ...teeup, location: e.target.value })}
                            />
                        </div>
                    </Tooltip>
                }
                {activeStep === 7
                    &&
                    <Tooltip
                        {...toolbarSetting}
                        isOpen={!!teeup && activeStep === 7}
                        content={this.renderRadioSelection(nearLocation, 'nearLocation', 7)
                        }>
                        <div className="input-wrapper">
                            <img src={where} />
                            <Input
                                placeholder="Suggest Where"
                                value={teeup ? teeup.location : ''}
                                onChange={e => onUpdateTeeUp({ ...teeup, location: e.target.value })}
                            />
                        </div>
                    </Tooltip>
                }

                {/* <Input
            placeholder="Name"
            value={teeup ? teeup.name : ''}
            onChange={e => onUpdateTeeUp({ ...teeup, name: e.target.value })}
        />
        <Input
            placeholder="Location"
            value={teeup ? teeup.location : ''}
            onChange={e => onUpdateTeeUp({ ...teeup, location: e.target.value })}
        /> */}
            </Modal>
        )
    }
}


export default CreateTeeupModal;
