import React from 'react'

import './tos.css'

const TOSView = ({ onNext, onChangeUniCode, onChangeAltCode, onChangePhoneCode, isValid, emailUni, phone }) => {
    return <div className="main-container">
        {/* <div id="start-container" onClick={onNext}> */}
        <div id="tos-container">
            <div id="tos-inner-container">
                <div className="font-demibold-header margin-bottom1 text-left">{'Coo-e Beta Study Terms & Conditions'}</div>
                <div className="font-demibold-big color-gray margin-bottom25 text-left">
                    Thank you for participating in our beta study.< br /><br />

                    This website and associated user-studies is for Coo-e, and Coo-e alone, and third parties are not responsible for the content presented and data collected.
                    Because we are currently interested in how university students react to our app and its features, we are looking for university student beta-testers. However,
                    no university is responsible in any way for the details of the studies Coo-e conducts.
                </div>

                <div className="font-demibold-big color-gray" id="start-info-text">
                    CONSENT TO PARTICIPATE IN A RESEARCH STUDY:<br /><br />
                    I will agree to participate in the Coo-e beta study. I understand that in doing so, my interactions with the application as well as my responses to any survey information will be accessible to members of the Coo-e Research Team to progress their work.<br /><br />

                    PRIVACY:<br /><br />
                    Coo-e is dedicated to protecting your personal information. Our full privacy policy is available as part of the download and install process of our Mobile application and applies to your use of any Coo-e-owned websites, software, orassociated services (collectively the “Platform”).
                    Our Privacy Policy discloses our privacy practices and explains to you how we use, collect, store, transfer, and share your personal information. You will be asked once your contact information is provided to install our mobile application. Please review the Privacy Policy provided
                    within the application periodically as we may revise it from time to time without notice. If you do not agree with or accept our Privacy Policy in its entirety, you must not access or use the Platform.

                    PURPOSE:<br /><br />
                    The purpose of this beta study is to provide information to better understand users of the Coo-e app, and to test the application in the early stages of its development. The information from the beta test will remain private and confidential to Coo-e researchers and will be only used to better advance the application.<br /><br />

                    PROCEDURE:<br /><br />
                    Participating in the beta study of Coo-e at this time will involve:<br />
                    -Downloading Coo-e on my mobile device<br />
                    -Creating an account with a username and password<br />
                    -Allowing Coo-e access to my contact phone contacts<br />

                    EXCLUSIONS:<br /><br />
                    You are not eligible to participate in the beta study of Coo-e if you are: <br />
                    -Under 18 years old<br />
                    -Not currently a NJIT or Rutgers student<br /><br />

                    RISKS/DISCOMFORTS:<br /><br />
                    During the study, you will be asked personal questions that may cause discomfort.<br />
                    I understand that Coo-e is not responsible for any injury or loss I may sustain in participating in this study.<br /><br />


                    CONFIDENTIALITY:<br /><br />
                    I understand that confidential is not the same as anonymous. Confidential means my name will not be disclosed if there exists a documental linkage between my identity and my responses as recorded in the research records. Every effort will be made to maintain confidentiality of study records. My identity will remain confidential unless disclosure is required by law.<br /><br /><br />


                    PAYMENT FOR PARTICIPATION:<br /><br />
                    I understand that my participation is voluntary and I may refuse to participate or may discontinue my participation in the study at any time. I also understand that the investigator has the right to withdraw me from the study. In order to receive payment, I understand that I must have a PayPal account and cooperate with Coo-e to receive payment.<br /><br />

                    RIGHT TO REFUSE OR WITHDRAW:<br /><br />
                    I understand that my participation is voluntary and I may refuse to participate, or may discontinue my participation at any time with no adverse consequence. I also understand that the investigator has the right to withdraw me from the study at any time.<br /><br />

                    INDIVIDUAL TO CONTACT:<br /><br />
                    If I have any additional questions about my rights as a research subject I may contact support@coo-e.com.<br /><br />

                    SIGNATURE OF PARTICIPANT<br /><br />
                    By clicking the 'I agree' button below I agree to Coo-e's terms and conditions. I agree to participate in the Coo-e beta study.</div>

                <div id='tos-button-container'>
                    {/* <div className={"rounded-button bcolor-pink margin-vertical15" + (isValid ? '' : ' bcolor-disabled')} onClick={onNext} > */}
                    <div className="rounded-button bcolor-pink margin-vertical15" onClick={onNext} >
                        <span className="rounded-button-text"> I Agree</span>
                    </div>
                </div>

            </div>
        </div>
    </div>
}

export default TOSView
