import React, { Component } from 'react'

import View from './appRedirectView'
import { connect } from "react-redux"
import { bindActionCreators } from 'redux'
import loginActions from '../../actions/loginActions'

class AppRedirectContainer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            check: false,
        }
        // setTimeout(() => {
        //     // After 30 seconds show login screen
        //     this.props.history.replace('login')
        // }, 30000)
    }

    syncChecking = () => {
        this.inteval = setInterval(() => {
            this.props.loginActions.checkContactSync();
        }, 5000);


    }
    componentDidMount() {
        this.setState({
            check: this.props.isSync
        });
        this.syncChecking()
    }

    componentDidUpdate(prevProps) {

        if (this.props.isSync !== prevProps.isSync) {
            clearInterval(this.inteval)
            this.props.history.replace('login')
        }
    }

    render() {
        return <View

        />
    }
}

const mapStateToProps = (state) => ({
    isSync: state.data.isSync,
    error: state.status.contactInfoSendError,
})

const mapDispatchToProps = (dispatch) => ({
    loginActions: bindActionCreators(loginActions, dispatch),

})
export default connect(mapStateToProps, mapDispatchToProps)(AppRedirectContainer)
