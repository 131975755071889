const loginProviders = {
    fb: 'facebook',
    google: 'google-oauth2',
    email: 'email',
}

const stepIds = {
    demographics: 'demographics',
    activities: 'activities',
    contacts: 'contacts',
    grouping: 'grouping',
    rating: 'rating',
    teeup: 'teeup',
}

module.exports = { loginProviders, stepIds }