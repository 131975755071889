import actionTypes from './actionTypes'
// SEARCH_INTERESTS: "SEARCH_INTERESTS",

// COLLECT_INTERESTS: "COLLECT_INTERESTS",
const searcInterests = (value) => ({ type: actionTypes.SEARCH_INTERESTS, payload: value })
const getUserInterests = () => ({ type: actionTypes.GET_USER_INTERESTS });
const getInterests = (data) => ({ type: actionTypes.GET_INTERESTS, payload: data })
const followInterest = (data) => ({ type: actionTypes.FOLLOW_INTERESTS, payload: data })
const createInterests = (data) => ({ type: actionTypes.CREATE_INTERESTS, payload: data });
const saveUserInterests = (data) => ({ type: actionTypes.SAVE_USER_INTERESTS, payload: data });
const unFollowInterests = (interestsId) => ({ type: actionTypes.UNFOLLOW_INTERESTS, payload: interestsId })

export default {
  getUserInterests,
  getInterests,
  searcInterests,
  followInterest,
  createInterests,
  saveUserInterests,
  unFollowInterests
}
