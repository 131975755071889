import { BrowserRouter, Route } from "react-router-dom";
import React from "react";
//components
import Initial from 'components/initial/initialContainer'
import Start from 'components/start/startContainer'
import ContactInfo from 'components/contactInfo/contactInfoContainer'
import ContactInfoTest from 'components/contactInfo/contactInfoContainerTest'
import VerifyInfo from 'components/verifyInfo/verifyInfoContainer'
import TOS from 'components/tos/tosContainer'
import Basics from 'components/basics/BasicsContainer'
import Desktop from 'components/desktop/desktopContainer'
import AppRedirect from 'components/appRedirect/appRedirectContainer'
import Lastpage from 'components/lastpage/lastpageContainer'
import Login from 'components/login/loginContainer'
import TeeupWaiting from 'components/teeupWaiting/teeupWaiting'

import StepIndicator from 'components/ui/StepIndicator'
import TopLogo from 'components/ui/topLogo'

import Activities from '../components/activities/ActivitiesContainer'
import Quiz from '../components/activities/QuizContainer'
import Selector from '../components/activities/ActivitiesSelectorContainer'
import InterestExample from '../components/activities/interestsActivities/InterestExample';
import InterestLists from '../components/activities/interestsActivities/InterestLists';
import AddInterests from '../components/activities/interestsActivities/AddInterests';
import EditContact from '../components/verifyInfo/editContact';

const customRouter = () => (
    <BrowserRouter>
        <div>
            <Route path="/" component={TopLogo} />
            <Route exact path="/" component={Initial} />
            <Route path="/start" component={Start} />
            <Route path="/contact-info/" component={ContactInfo} />
            <Route path="/verify-info/" component={VerifyInfo} />
            <Route path="/edit-info/" component={EditContact} />
            <Route path="/app-redirect/" component={AppRedirect} />
            <Route path="/login/" component={Login} />
            <Route path="/tos/" component={TOS} />
            <Route path="/survey" component={StepIndicator} />
            <Route path="/survey/basics" component={Basics} />

            <Route path={"/survey/activities/selector"} component={Selector} />
            <Route path={"/survey/activities/activities"} component={Activities} />
            <Route path={"/survey/activities/quiz"} component={Quiz} />
            <Route path={"/survey/activities/interests"} component={InterestExample} />
            <Route path={"/survey/activities/interestsList"} component={InterestLists} />
            <Route path={"/survey/activities/addinterestsList"} component={AddInterests} />
            {/* 
            <Route path={"/survey/activities/" + activitiesEndpoints.campus} component={Activities} />
            <Route path={"/survey/activities/" + activitiesEndpoints.campusQuiz} component={Quiz} />
            <Route path={"/survey/activities/" + activitiesEndpoints.campusSelector} component={Selector} />
            <Route path={"/survey/activities/" + activitiesEndpoints.classRelated} component={Activities} />
            <Route path={"/survey/activities/" + activitiesEndpoints.classRelatedQuiz} component={Quiz} />
            <Route path={"/survey/activities/" + activitiesEndpoints.classSelector} component={Selector} />
            <Route path={"/survey/activities/" + activitiesEndpoints.work} component={Activities} />
            <Route path={"/survey/activities/" + activitiesEndpoints.workQuiz} component={Quiz} />
            <Route path={"/survey/activities/" + activitiesEndpoints.workSelector} component={Selector} />
            <Route path={"/survey/activities/" + activitiesEndpoints.interestGroups} component={Activities} />
            <Route path={"/survey/activities/" + activitiesEndpoints.interestGroupsQuiz} component={Quiz} /> */}
            <Route path="/survey/activities/desktop/" component={Desktop} />
            <Route path="/last" component={Lastpage} />
            <Route path="/teeup" component={TeeupWaiting} />


            <Route path="/contact-info-test/" component={ContactInfoTest} />

        </div>
    </BrowserRouter>
);

export default customRouter;
