import _ from 'lodash'

import actionTypes from '../actions/actionTypes'
import { stepIds } from 'configs/enums'

let idToIndex = (id) => {
    let index = 0
    switch (id) {
        case stepIds.demographics: {
            index = 0
            break
        }

        case stepIds.activities: {
            index = 1
            break
        }

        case stepIds.contacts: {
            index = 2
            break
        }

        case stepIds.grouping: {
            index = 3
            break
        }

        case stepIds.rating: {
            index = 4
            break
        }

        case stepIds.teeup: {
            index = 5
            break
        }
    }
    return index
}

export const initialState = {
    structure: [
        {
            title: 'Demographics',
            finished: false,
        },
        {
            title: 'Activities',
            finished: false
        },
        {
            title: 'Contacts',
            finished: false
        },
        {
            title: 'Grouping',
            finished: false
        },
        {
            title: 'Rating',
            finished: false
        },
        {
            title: 'TeeingUp',
            finished: false
        },
    ],
    currentIndex: 0,
}

export default (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.LOGOUT: {
            return initialState
        }
        case actionTypes.FINISH_STEP: {
            const { id } = action.payload
            const finishedIndex = idToIndex(id)
            const structure = state.structure.map((step, index) => {
                if (index === finishedIndex) {
                    step.finished = true
                }
                return step
            })
            return _.assign({}, state, structure)
        }

        case actionTypes.SET_ACTIVE_STEP: {
            const { id } = action.payload
            let ids = id;
            return _.assign({}, state, { currentIndex: idToIndex(ids) })
        }

        default:
            return state
    }
}