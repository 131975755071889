const actionTypes = {
    //User/Login
    AUTH_GOOGLE: 'AUTH_GOOGLE',
    LOGOUT: 'LOGOUT',

    LOGIN_EMAIL: 'LOGIN_EMAIL',
    LOGIN_FB: 'LOGIN_FB',
    LOGIN_GOOGLE: 'LOGIN_GOOGLE',
    LOGIN_END: 'LOGIN_END',
    AUTH_END: 'AUTH_END',
    LOGIN_SOCIAL_CREDENTIALS: 'LOGIN_SOCIAL_CREDENTIALS',

    // Start pages
    CONTACT_INFO_SET_TOS: 'CONTACT_INFO_SET_TOS',
    CONTACT_INFO_SEND: 'CONTACT_INFO_SEND',
    CONTACT_INFO_SENT: 'CONTACT_INFO_SENT',
    CONTACT_INFO_VERIFY: 'CONTACT_INFO_VERIFY',
    CONTACT_INFO_VERIFY_SENT: 'CONTACT_INFO_VERIFY_SENT',
    CONTACT_INFO_INIT: 'CONTACT_INFO_INIT',
    VERIFY_INFO_INIT: 'VERIFY_INFO_INIT',
    CONTACT_INFO_CHANGE: 'CONTACT_INFO_CHANGE',

    // Steps
    FINISH_STEP: 'FINISH_STEP',
    SET_ACTIVE_STEP: 'SET_ACTIVE_STEP',

    //activities
    SET_ACTIVITY_QUESTIONS: 'SET_ACTIVITY_QUESTIONS',
    SET_ACTIVITY_SELECTOR: 'SET_ACTIVITY_SELECTOR',

    // Status
    ERROR: 'ERROR',
    CLEAR_ERROR: 'CLEAR_ERROR',

    // Social desktop
    SEND_ACTIVITY_GROUPS: 'SEND_ACTIVITY_GROUPS',
    SEND_CONTACT_GROUPS: 'SEND_CONTACT_GROUPS',
    SEND_BUCKET_GROUPS: 'SEND_BUCKET_GROUPS',
    SEND_ATTRIBUTE_GROUPS: 'SEND_ATTRIBUTE_GROUPS',
    DESKTOP_SAVE: 'DESKTOP_SAVE',
    GET_DESKTOP_STATE: 'GET_DESKTOP_STATE',
    POST_DESKTOP_STATE: 'POST_DESKTOP_STATE',
    DELETE_GROUP: 'DELETE_GROUP',

    // demographics
    SEND_BASICS_QUESTIONS: 'SEND_BASICS_QUESTIONS',
    SEND_ACTIVITY_QUESTIONS: 'SEND_ACTIVITY_QUESTIONS',
    SEND_ACTIVITY_QUESTIONS_END: 'SEND_ACTIVITY_QUESTIONS_END',

    GET_DEMOGRAPHICS_META: 'GET_DEMOGRAPHICS_META',
    GET_LANGUAGES_END: 'GET_LANGUAGES_END',
    GET_MAJORS_END: 'GET_MAJORS_END',

    //contacts
    FETCH_CONTACTS: 'FETCH_CONTACTS',
    SAVE_CONTACTS: 'SAVE_CONTACTS',
    ADD_CONTACT: 'ADD_CONTACT',
    EDIT_CONTACT: 'EDIT_CONTACT',
    FORMAT_CONTACT: "FORMAT_CONTACT",
    SET_FORMATED_CONTACT: "SET_FORMATED_CONTACT",

    //teeup
    GET_RECOMMENDED_TEEUP: 'GET_RECOMMENDED_TEEUP',
    GET_TEEUP: 'GET_TEEUP',
    GET_TEEUP_RESPONSE: 'GET_TEEUP_RESPONSE',
    GET_RECOMMENDED_TEEUP_SUCCESS: 'GET_RECOMMENDED_TEEUP_SUCCESS',
    GET_RECOMMENDED_TEEUP_ERROR: 'GET_RECOMMENDED_TEEUP_ERROR',
    POST_DRAFTED_TEEUP: 'POST_DRAFTED_TEEUP',
    POST_DRAFTED_TEEUP_SUCCESS: 'POST_DRAFTED_TEEUP_SUCCESS',
    POST_DRAFTED_TEEUP_ERROR: 'POST_DRAFTED_TEEUP_ERROR',
    UPDATE_TEEUP: 'UPDATE_TEEUP',
    FETCH_TEELUS_INFORMATION: 'FETCH_TEELUS_INFORMATION',
    REPOPULATE_TEEUPS: 'REPOPULATE_TEEUPS',
    FETCH_BASICS_QUESTIONS: 'FETCH_BASICS_QUESTIONS',

    // End Page
    ADD_PAYMENT: 'ADD_PAYMENT',
    PAYMENT_STATUS: 'PAYMENT_STATUS',
    CLEAR_PAYMENT_STATUS: 'CLEAR_PAYMENT_STATUS',

    DELETED_UID: 'DELETED_UID',

    // Interests
    SEARCH_INTERESTS: "SEARCH_INTERESTS",
    COLLECT_INTERESTS: "COLLECT_INTERESTS",
    FOLLOW_INTERESTS: "FOLLOW_INTERESTS",
    GET_USER_INTERESTS: "GET_USER_INTERESTS",
    GET_INTERESTS: "GET_INTERESTS",
    CREATE_INTERESTS: "CREATE_INTERESTS",
    SAVE_USER_INTERESTS: "SAVE_USER_INTERESTS",
    UNFOLLOW_INTERESTS: "UNFOLLOW_INTERESTS",

    // CHANGE 
    CLEAR_VERIFY_DATA: "CLEAR_VERIFY_DATA",
    EMAIL_CHANGE: "EMAIL_CHANGE",
    PHONE_CHANGE: "PHONE_CHANGE",


    //
    CHECK_IS_SYNCHRONED: 'CHECK_IS_SYNCHRONED',
    IS_CONTACT_SYNHRONED: 'IS_CONTACT_SYNHRONED',
    UPDATE_CONTACTS: "UPDATE_CONTACTS"


}

export default actionTypes