import React from 'react'

import './verifyInfo.css'
import LoadingIndicator from 'components/ui/loadingIndicator'
import pen from 'assets/svg/pen.svg';
import save from 'assets/svg/save.svg';

const check = (flag) => {
    if (flag === "disabled") {

    } if (flag === "enabled") {

    } if (flag === "checked") {

    }
}

const VerifyInfoView = ({ loading, onNext, onChangeUniCode, emailVerify, onChangePhoneCode, isValid, emailUni, phone, emailCheck, phoneCheck, emailUniEdit, onEditBtn, phoneVerify, isPhoneEnabled, isEmailEnabled, emailClick, phoneClick, messageEmail, messagePhone }) => {
    let valid;
    let emailEror;
    let phoneError;

    if (emailClick && !emailCheck) {
        emailEror = true
    } else {
        emailEror = false;
    }

    if (phoneClick) {
        if (!phoneCheck) {
            phoneError = true
        } else {
            phoneError = false;
        }
    }

    if (phoneCheck && emailCheck)
        valid = true;
    else
        valid = false;

    return <div className="main-container">
        {/* <div id="start-container" onClick={onNext}> */}
        <div id="verify-container">
            <div className="font-demibold-header margin-bottom1">Verify your contact information</div>
            <div className="font-demibold-big color-gray margin-bottom25">We sent a verification code to the following:</div>

            <div>
                {/* <div className="editContainer">
                    {
                        emailUniEdit ?
                            <img src={save} className="edit_info_icons" onClick={() => saveChanges('emailUni')} />
                            :
                            <img src={pen} className="edit_info_icons" onClick={() => edit('emailUni')} />
                    }
                </div> */}
                <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                    <input className="info_input" value={emailUni} disabled name="emailUni" style={{ paddingRight: '10px', marginTop: '-14px' }} />
                    <input className="input-field font-regular-big margin-bottom1" style={{ width: '200px' }} onChange={onChangeUniCode} type="text" placeholder="Enter code" />
                    {
                        isEmailEnabled === "enabled" && !emailCheck ?
                            <div style={{ width: '80px', height: '40px', background: '#f42862', marginLeft: '-84px', cursor: 'pointer', borderRadius: '5px', marginTop: '-14px', display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center', color: 'white' }}
                                onClick={emailVerify}
                            >Verify </div>
                            : null
                    }
                    {
                        isEmailEnabled === "disabled" && !emailCheck ?
                            <div style={{
                                width: '80px', height: '40px', background: '#e1e1e1', marginLeft: '-84px', borderRadius: '5px', marginTop: '-14px', display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center', color: 'white'
                            }}>Verify </div>
                            : null
                    }
                    {
                        emailCheck ?
                            <div style={{ width: '80px', height: '40px', background: '#00c5ae', marginLeft: '-84px', borderRadius: '5px', marginTop: '-14px', display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center', color: 'white' }}>Verified</div>
                            : null
                    }
                    {

                        valid ?
                            <span style={{ fontFamily: "GreycliffCF-DemiBold", fontSize: '16px', color: '#9d9d9d', marginLeft: '10px', marginTop: '-14px' }}>resend code</span>
                            :
                            <span style={{ fontFamily: "GreycliffCF-DemiBold", fontSize: '16px', color: '#f42862', cursor: 'pointer', marginLeft: '10px', marginTop: '-14px' }}>resend code</span>
                    }

                </div>
                {isEmailEnabled === "disabled" && !emailCheck ?
                    null :
                    emailEror ? <span style={{ position: 'relative', top: '-15px', left: '200px', fontSize: '12px', fontFamily: "GreycliffCF-DemiBold", color: 'tomato' }}>{messageEmail}</span> : null
                }

            </div>

            <div>
                {/* <div className="editContainer"  >
                    {
                        phoneEdit ?
                            <img src={save} className="edit_info_icons" style={{ marginRight: "10px" }} onClick={() => saveChanges('phone')} />
                            :
                            <img src={pen} className="edit_info_icons" style={{ marginRight: "10px" }} onClick={() => edit('phone')} />
                    }
                    <span style={{ position: 'rleative', padding: '5px' }}><b>+1</b></span>

                    <input className="info_input" disabled={!phoneEdit} onChange={onChangeInfo} name="phone" value={phone} style={{ marginLeft: '-40px' }} />
                    <div className={!phoneEdit ? 'filed filedDisabled' : 'filed filedEnabled'} ></div>
                </div> */}
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <input className="info_input" disabled name="phone" value={phone} style={{ paddingRight: '10px', marginTop: '-14px' }} />
                    <input className="input-field font-regular-big margin-bottom1" style={{ width: '200px' }} onChange={onChangePhoneCode} type="text" placeholder="Enter code" />
                    {
                        isPhoneEnabled === "enabled" && !phoneCheck ?
                            <div style={{ width: '80px', height: '40px', background: '#f42862', marginLeft: '-84px', cursor: 'pointer', borderRadius: '5px', marginTop: '-14px', display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center', color: 'white' }}
                                onClick={phoneVerify}
                            >Verify </div>
                            : null
                    }
                    {
                        isPhoneEnabled === "disabled" && !phoneCheck ?
                            <div style={{ width: '80px', height: '40px', background: '#e1e1e1', marginLeft: '-84px', borderRadius: '5px', marginTop: '-14px', display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center', color: 'white' }}>Verify </div>
                            : null
                    }
                    {
                        phoneCheck ?
                            <div style={{ width: '80px', height: '40px', background: '#00c5ae', marginLeft: '-84px', borderRadius: '5px', marginTop: '-14px', display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center', color: 'white' }}>Verified</div>
                            : null
                    }
                    {

                        valid ?
                            <span style={{ fontFamily: "GreycliffCF-DemiBold", fontSize: '16px', color: '#9d9d9d', marginLeft: '10px', marginTop: '-14px' }}>resend code</span>
                            :
                            <span style={{ fontFamily: "GreycliffCF-DemiBold", fontSize: '16px', color: '#f42862', cursor: 'pointer', marginLeft: '10px', marginTop: '-14px' }}>resend code</span>
                    }
                </div>
                {isPhoneEnabled === "disabled" && phoneCheck ?
                    null :
                    phoneError ? <span style={{ position: 'relative', top: '-15px', left: '200px', fontSize: '12px', fontFamily: "GreycliffCF-DemiBold", color: 'tomato' }}>{messagePhone}</span> : null
                }
                {/* {
                    phoneError ? <span style={{ position: 'relative', top: '-15px', left: '200px', fontSize: '12px', fontFamily: "GreycliffCF-DemiBold", color: 'tomato' }}>{messagePhone}</span> : null
                } */}
            </div>
            <div style={{ display: 'flex' }}>
                {
                    valid ?
                        <div className={"rounded-button margin-vertical15 bcolor-disabled"} style={{
                            marginRight: "50px"
                        }}>
                            <span className="rounded-button-text">Edit info</span>
                        </div>
                        :
                        <div className={"rounded-button bcolor-pink margin-vertical15"} style={{
                            backgroundColor: '#767676',
                            marginRight: "50px"
                        }} onClick={onEditBtn} >
                            <span className="rounded-button-text">Edit info</span>
                        </div>

                }
                <div className={"rounded-button bcolor-pink margin-vertical15" + (valid ? '' : ' bcolor-disabled')} onClick={onNext} >
                    <span className="rounded-button-text">Continue</span>
                </div>
            </div>
        </div>
        {loading && <LoadingIndicator />}
    </div >
}

export default VerifyInfoView
