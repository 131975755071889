import React from 'react'

import './startStyles.css'

import phone from '../../assets/img/phone/phone.png'

import { Link } from 'react-router-dom'

const StartView = ({ onStart, onAcceptTos, tosAccepted }) => {
    return <div className="main-container">
        <div id="start-container">
            <div id="start-left-column">
                <div className="font-demibold-header" id="header-text">Welcome to the TellUs us Beta Test</div>
                <div className="font-demibold-big color-gray" id="start-info-text">Hello!<br /><br />

                    We are developing an app called Coo-e and we would like you to help us with our user-studies and Beta Testing.<br /><br />

                    Coo-e is an app that helps you coordinate social activities with your friends, study groups, and more. It will so help you find other people to do group activities with, and places with deals.<br /><br />

                    From February 17 th to 21 st we are looking for a select group of individuals to test our Beta-Tester survey and recruitment system, for which we will pay $20. This will involve taking a survey to TellUs more about your social activities, and who you do these activities with. You will be required to download a version of the app so we can ask you questions about who you coordinate with.<br /><br />

                    Also, don’t worry, your personal information and contact data is going to remain private, and will just be used within the app to help you have a better experience. We promise!<br /><br />

                    Individuals that who complete this testing task successfully will be offered additional paid $20 beta-testing opportunities using the app.<br /><br />

                    If you do or would like to do social activities with a group of people, then you’re exactly the kind of beta tester we need!<br /><br />

                    Get started and earn $20<br /><br />

                    If you have any questions about the survey, please contact us at support@coo-e.com</div>
                {/* left column: title, text, button */}

                <div className="font-demibold-big color-gray" id="start-container" style={{ justifyContent: 'center' }}>
                    <div id="start-tos">
                        <div>
                            <label className="checkbox-container margin-right05">
                                <input type="checkbox" checked={tosAccepted} onChange={onAcceptTos} />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                        <div id="start-tos-text">
                            By checking this, you have read, understood, and agree with the <Link className='color-gray' to="tos"><u>Terms and Conditions</u></Link>
                        </div>
                    </div>
                </div>

                <div className={"rounded-button bcolor-pink" + (tosAccepted ? '' : ' bcolor-disabled')} onClick={onStart}>
                    <span className="rounded-button-text">Start Survey</span>
                </div>
            </div>
            <div id="start-right-column">
                <img id="start-phone-image" src={phone} alt="" />
            </div>
        </div>


    </div >
}

export default StartView
