import _ from 'lodash'

import actionTypes from 'actions/actionTypes'

export const initialState = {}

export default (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.LOGOUT: {
            return initialState
        }

        case actionTypes.DESKTOP_SAVE: {
            return action.payload
        }


        case actionTypes.DELETE_GROUP: {
            const group = action.payload
            let groups = state.groups ? state.groups : []
            let filteredGroups = groups.filter(singleGroup => singleGroup.uid !== group.uid)
            return _.assign({}, state, { groups: filteredGroups })
        }

        default:
            return state
    }
}