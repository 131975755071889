import React from 'react'
import './Activities.css'
import _ from 'lodash'
import PlacesAutocomplete, {
} from 'react-places-autocomplete';

const actionsOptions = [
    'attend',
    'discuss',
    'go',
    'host',
    'learn',
    'listen to',
    'make',
    'play',
    'read',
    'teach',
    'visit',
    'watch',
    'write',
    'work',
    'study'
]

const statusOptions = [
    'currently',
    'would like to'
]

const memberChoices = [
    'yes',
    'no'
]


const validateInput = (value, initialValidation) => {

    let isInvalid = true
    if ((value && (typeof value === 'string') && value.trim() !== '') || initialValidation) {
        isInvalid = false
    }
    return isInvalid

    // return (value && (typeof value === 'string') && value.trim() === '' && !initialValidation)
}
const interestGroups = 'interestGroups'

const renderHeader = (name) => {
    let value;
    switch (name) {
        case 'campus':
            value = "Campus Clubs and Organizations";
            break;
        case 'classRelated':
            value = "Classmates"
            break;
        case 'work':
            value = "Workmates on or near campus"
            break;
        case 'interestGroups':
            value = "Interest-based Social Group Activities"
            break
    }

    return value;
}

const renderSecounText = (name) => {
    let value;
    switch (name) {
        case 'campus':
            value = "[e.g. The Vector and Student Senate]";
            break;
        case 'classRelated':
            value = ""
            break;
        case 'work':
            value = ""
            break;
        case 'interestGroups':
            value = "[e.g. Pick-up Basketball, Online Gaming with campus friends, Ultimate Frisbee]"
            break
    }

    return value
}

const renderQuestions = (questions, updateQuestionProperty, initialValidation, activityType, deleteActivity, undeletableAmount, name) => {
    return (
        <div>
            <div style={{ width: '50%', margin: '0 auto' }}>
                <h1 className="txt_top" style={{ marginTop: '10px', marginLeft: '50px' }}>{renderHeader(name)}</h1>
                <span className="secoundary-text" style={{ position: 'relative', top: -10, left: '50px' }}>{renderSecounText(name)}</span>
            </div>
            <div >
                <table className="activity-table" >
                    <thead >
                        <tr >
                            <th></th>
                            {name !== interestGroups && <th></th>}
                            {/* {name === interestGroups && <th>Status</th>} */}
                            <th >Action</th>
                            <th>Activity</th>
                            {name !== interestGroups && <th>with</th>}
                            <th>Location</th>
                            {activityType === 'campus' && <th>Member</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {/* <tr className='activity-table activity-table-example'>
                        <td className='activity-table-first-element'>***Example</td>
                        {activityType !== interestGroups && <td style={{ paddingRight: '6px' }}>I</td>}
                        {activityType === interestGroups && <td className='activity-table-padding'>
                            <select className="input-form select-form action-input-status">
                                <option value="" disabled selected hidden>I currently</option>
                            </select>
                        </td>}
                        <td style={{ paddingRight: '16px' }}>
                            <select className="input-form select-form action-input orange">
                                <option value="" disabled selected hidden>attend</option>
                            </select>
                        </td>
                        <td className='activity-table-padding'>
                            <input className=" input-form activity-input red"
                                value="executive board editorial meetings" type="text" disabled={true} />
                        </td>
                        {
                            activityType !== interestGroups &&
                            <td className='activity-table-padding '>
                                <input className=" input-form with-input blue"
                                    value="The Vector" type="text" disabled={true} />
                            </td>
                        }
                        <td className='activity-table-padding'>
                            <input className=" input-form with-input purple"
                                value="The Campus Center 4th Floor" type="text" disabled={true} />
                        </td>
                        {activityType === 'campus' &&
                            <td style={{ paddingRight: '16px' }}>
                                <select className="input-form select-form action-input">
                                    <option value="" disabled selected hidden>Yes</option>
                                </select>
                            </td>
                        }
                    </tr> */}
                        {questions.map((question, index) => (
                            <tr key={index} className='activity-table'>
                                <td>{index >= undeletableAmount && <i onClick={() => deleteActivity(index, name)} className='trash'></i>}</td>
                                {name !== "interestGroups" && <td style={{ paddingRight: '16px' }}>I</td>}
                                {/* {
                                name === "interestGroups" &&
                                <td >
                                    <select
                                        className={"input-form select-form action-input-status action-input " + (validateInput(question.activity, initialValidation) && ' input-form-invalid')}
                                        value={question.status}
                                        onChange={(event) => updateQuestionProperty('status', event.target.value, index, name)}>
                                        <option value="" disabled selected hidden></option>
                                        {statusOptions.map(option => (
                                            <option key={option} value={option}>I {option}</option>
                                        ))}
                                    </select>
                                </td>
                            } */}
                                <td>
                                    <select
                                        className={"input-form select-form action-input orange " + (validateInput(question.activity, initialValidation) && ' input-form-invalid')}
                                        value={question.action}
                                        onChange={(event) => updateQuestionProperty('action', event.target.value, index, name)}>
                                        <option value="" disabled selected hidden></option>
                                        {actionsOptions.map(option => (
                                            <option key={option} value={option}>{option}</option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <input
                                        className={' input-form activity-input red action-input ' + (validateInput(question.activity, initialValidation) && ' input-form-invalid')}
                                        onChange={(event) => updateQuestionProperty('activity', event.target.value, index, name)}
                                        value={question.activity} type="text" />
                                </td>
                                {
                                    name !== 'interestGroups' &&
                                    <td>
                                        <input
                                            className={" input-form with-input blue action-input " + (validateInput(question.with, initialValidation) && ' input-form-invalid')}
                                            onChange={(event) => updateQuestionProperty('with', event.target.value, index, name)}
                                            value={question.with} type="text" />
                                    </td>
                                }
                                <td style={name === "interestGroups" ? { width: '320px' } : null}>
                                    {/* <input
                                    className={" input-form with-input purple " + (validateInput(question.location, initialValidation) && ' input-form-invalid')}
                                    onChange={(event) => updateQuestionProperty('location', event.target.value, index)} */}
                                    {/* value={question.location} type="text" /> */}
                                    <PlacesAutocomplete
                                        value={question.location}
                                        onChange={address => updateQuestionProperty('location', address, index, name)}
                                        onSelect={address => updateQuestionProperty('location', address, index, name)}
                                    >
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                            <div>
                                                <input
                                                    {...getInputProps({
                                                        placeholder: " Enter a city...",
                                                        className: 'input-form with-input purple',
                                                    })}
                                                    style={name === "interestGroups" ? { width: '320px' } : null}
                                                />
                                                <div className="autocomplete-dropdown-container">
                                                    {loading && <div>Loading...</div>}
                                                    {suggestions.map(suggestion => {
                                                        const className = 'suggestion-item'

                                                        // inline style for demonstration purpose
                                                        const style = suggestion.active
                                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                        return (
                                                            <div
                                                                {...getSuggestionItemProps(suggestion, {
                                                                    className,
                                                                    style,
                                                                })}
                                                            >
                                                                <span>{suggestion.description}</span>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                                </td>
                                {activityType === 'campus' &&
                                    <td>
                                        <select
                                            className={"input-form select-form action-input " + (validateInput(question.member, initialValidation) && ' input-form-invalid')}
                                            value={question.member}
                                            onChange={(event) => updateQuestionProperty('member', event.target.value, index, name)}>
                                            >
                                <option value="" disabled selected hidden></option>
                                            {memberChoices.map(option => (
                                                <option key={option} value={option}>{option}</option>
                                            ))}
                                        </select>
                                    </td>
                                }
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

const ActivitiesView = ({
    paragraph1, paragraph2, examples, badExamples, questions, updateQuestionProperty, addActivity, onNext,
    onBack, initialValidation, activityType, deleteActivity, undeletableAmount, name
}) => {
    return (
        <div>
            <div>
                {renderQuestions(questions, updateQuestionProperty, initialValidation, activityType, deleteActivity, undeletableAmount, name)}
            </div>
            <div className="addAnotherActivity">
                <p className='add-activity-button' onClick={() => addActivity(name)}>+ Add another activity</p>
            </div>
            {/* <div style={{ paddingTop: '137px' }} />
            <div className='activity-button-container'>
                <div className={"rounded-button bcolor-pink"} onClick={onBack}>
                    <span className="rounded-button-text">Back</span>
                </div>
                <div className={"rounded-button bcolor-pink"} onClick={onNext}>
                    <span className="rounded-button-text">Next</span>
                </div>
            </div> */}
        </div>
    )
}

export default ActivitiesView