import React, { Component } from 'react'

import { connect } from "react-redux"
import { bindActionCreators } from 'redux'
import loginActions from '../../actions/loginActions'

import { validateEmail } from '../../utils/dataUtils'
import View from './tosView'

class TOSContainer extends Component {

    onNext = () => {
        this.props.loginActions.acceptTos(true)
        // this.props.history.replace('/')
        this.props.history.goBack()

        // alert('Congratulations, finished with registration')
    }

    render() {

        return <View
            onNext={this.onNext}
        />
    }
}

const mapStateToProps = (state) => ({
    emailUni: state.data.emailUni,
    phone: state.data.phone,
})

const mapDispatchToProps = (dispatch) => ({
    loginActions: bindActionCreators(loginActions, dispatch),

})

export default connect(mapStateToProps, mapDispatchToProps)(TOSContainer)