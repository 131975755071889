import React from 'react'

import phone from '../../assets/img/phone/phone.png'
import './lastPage.css';
import { Link } from 'react-router-dom'

const AppRedirectView = ({ onStart, address, addressChange, sendAddress, newTeeup }) => {
    return <div className="main-container">
        <div id="redirect-container">
            <div id="redirect-title-text">
                <h1 className="tee-up-sent">Tee Up Sent!</h1>
                <p className="youve-just-earned">You’ve just earned $20</p>
                <h2 className="enter-your-email-acc">Enter you mail id, <br />We will send a link to claim to your money via PayPal</h2>
            </div>
            <div className=" input-container">
                <input className="last-input-form input-form-text"
                    placeholder="hci123@njit.edu" type=" text"
                    onChange={(event) => addressChange(event.target.value)}
                />
            </div>
            <p className="to-earn-another-20">
                To earn <span className="text-style-1">another $20</span> please do the following:
                Use the Tee-Up to coordinate activities with your group of friends!
                Be sure to check Coo-e Research Central for more opputunities to earn money.
            </p>
            <div className="buttonContainer">
                <div className={`rectangle active`} onClick={newTeeup}>
                    <p className='newTeeup'>New TeeUp</p>
                </div>
                <div className={`rectangle ${address === '' ? 'disabled' : 'active'}`} onClick={sendAddress}>
                    <p className='next'>Next</p>
                </div>
            </div>
        </div>
    </div >
}

export default AppRedirectView
