import React from 'react'
import ReactDOM from 'react-dom'
import { DragDropContainer, DropTarget } from 'react-drag-drop-container'
import Draggable from 'react-draggable'

import './boxItem.css'

import grabber from 'assets/svg/grabber.svg'

/*
    BoxItem - a thing that appears in a box, after you drag something into the box
*/

export default class GroupItem extends React.Component {
    // the things that appear in the boxes
    constructor(props) {
        super(props)

        this.state = {
            initial: true,
            isDragged: false,
            renaming: false,
            typed: props.typed && props.typed !== 'unnamed' ? props.typed : '',
        }
    }

    onDragStop = (e) => {
        if (this.props.onDragStop) {

            var rect = ReactDOM.findDOMNode(this.viewRef)
                .getBoundingClientRect()

            // this.props.onDragStop(e, this.props.groupItem)
            this.props.onDragStop(rect, this.props.groupItem)
        }
    }

    onDragStart = (e) => {
        this.setState({ initial: false, isDragged: false })
    }

    onDrag = (e) => {
        if (!this.state.isDragged) {
            this.setState({ isDragged: true })
        }

        if (this.props.onDrag) {
            this.props.onDrag(this.props)
        }
    }

    onTap = () => {
        if (!this.state.isDragged && this.props.onItemTapped) {
            this.props.onItemTapped(this.props)
        }
    }

    deleteMe = () => {
        this.props.kill(this.props.uid, true)
    }

    onBlur = (event) => {
        let typed = event.target.value

        if (!typed || typed.length == 0) {
            this.deleteMe()
            this.props.onGroupName(this.props.uid, null)
        }
        else {
            // this.setState({ typed, initial: false })
            this.setState({ typed }, () => {
                this.setState({ initial: false, renaming: false })
            })
            this.props.onGroupName(this.props.uid, typed)
        }
    }

    onKeyPress(event) {
        if (event.keyCode == 13) {
            event.target.blur()
        }
    }

    rename = () => {
        this.setState({ renaming: true })
    }

    render() {
        /*
          Notice how these are wrapped in a DragDropContainer (so you can drag them) AND
          in a DropTarget (enabling you to rearrange items in the box by dragging them on
          top of each other)
        */

        let { name, uid, draggable, position, activeGroupUid, callback, groupColor, onContextMenu } = this.props
        let { initial, typed, renaming } = this.state

        let selected = activeGroupUid == uid
        let style = null//{ backgroundColor: 'white', borderColor: groupColor }

        let design = <div className={"outer box_item_component" + ' group' + uid} >
            <div className={"item group" + (selected ? ' group-selected' : '')} style={selected ? { borderColor: '#00bcf6' } : { borderColor: groupColor }} onContextMenu={(event) => {
                onContextMenu(event)
                callback(event, this)
            }}
                onClick={this.onTap}>
                <div className="content">
                    {renaming || (initial && !typed) ? <input defaultValue={typed} onKeyDown={this.onKeyPress} autoFocus onBlur={this.onBlur.bind(this)} /> : typed}
                </div>

                <div className="grabber">
                    <img draggable="false" src={grabber} />
                </div>
            </div>
        </div>

        let container = initial && !typed ? <div className="draggable_absolute" style={{ left: position.x, zIndex: 100 }}>{design}</div>
            : <Draggable ref={ref => this.viewRef = ref} defaultClassName="draggable_absolute" onStart={this.onDragStart} onStop={this.onDragStop} onDrag={this.onDrag} position={initial ? position : undefined} grid={[25, 25]} bounds='parent'>
                {design}
            </Draggable>

        return container
    }
}