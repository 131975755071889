import React, { Component } from 'react'
import close from 'assets/svg/circleColse.svg'

class SingleInterest extends Component {
  state = { hover: false }


  onMouseEnter = () => {
    this.setState({ hover: true })
  }

  mouseLeave = () => {
    this.setState({
      hover: false
    })
  }

  render() {
    const { hover } = this.state;
    const { name, deleteInterest, id } = this.props;
    return (
      <span
        style={{ margin: '2px', color: 'white', background: '#363fa2', float: 'left', border: 'solid 1px #363fa2', borderRadius: '20px', textAlign: 'center', }}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.mouseLeave}
      >
        <span style={{ fontSize: 25, marginRight: '10px', marginLeft: '10px', float: 'left' }}>&#9829;</span>
        <span style={{ position: 'relative', top: '7px', marginRight: '10px', float: 'left' }}>{name}</span>
        <div //#endregion
          style={{ float: 'right', marginTop: '5px', marginRight: '10px' }}
        >
          <img onClick={() => deleteInterest(id)} style={{ cursor: 'pointer' }} src={close} />
        </div>
      </span>
    )
  }
}

export default SingleInterest