import { createStore, compose, applyMiddleware } from 'redux'
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'
import thunk from 'redux-thunk'
import reducers from './reducers'
import { persistStore, persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/es/storage'
import { createLogger } from 'redux-logger'

import loginMiddleware from './middlewares/loginMiddleware'
import backendMiddleware from './middlewares/backendMiddleware'

const config = {
    key: 'root',
    storage,
    blacklist: ['status', 'steps'],
}

const reducer = persistCombineReducers(config, reducers)

function configureStoreProd(initialState, persistorCallback) {
    const middlewares = [
        loginMiddleware,
        backendMiddleware,

        thunk,
    ]

    const store = createStore(reducer, initialState, compose(
        applyMiddleware(...middlewares),
    )
    )
    let persistor = persistStore(store, null, persistorCallback)

    return { store, persistor }
}

function configureStoreDev(initialState, persistorCallback) {
    const middlewares = [
        loginMiddleware,
        backendMiddleware,

        createLogger({}),
        reduxImmutableStateInvariant(),

        thunk,
    ]

    // eslint-disable-next-line
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools

    const store = createStore(reducer, initialState, composeEnhancers(
        applyMiddleware(...middlewares),
    )
    )
    let persistor = persistStore(store, null, persistorCallback)
    // persistor.purge()
    //   if (module.hot) {
    //     // Enable Webpack hot module replacement for reducers
    //     module.hot.accept('../reducers', () => {
    //       const nextReducer = require('../reducers').default; // eslint-disable-line global-require
    //       store.replaceReducer(nextReducer);
    //     });
    //   }

    return { store, persistor }
}

const configureStore = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev

export default configureStore