import React, { Component } from 'react'

import View from './lastpageView'
import { connect } from "react-redux"
import { bindActionCreators } from 'redux'
import paymentActions from 'actions/paymentActions'
import clearPayment from 'actions/paymentActions'
import { validateEmail } from '../../utils/dataUtils'
import PopUp from './popUp';

class AppRedirectContainer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            address: '',
            isVisible: false,
        }
    }

    closePopUp = () => {
        this.setState({ isVisible: false, address: '' })
        this.props.clearPayment.clearPaymentStatus()
    }

    onAddressChange = (address) => {
        this.setState({ address: address })
    }

    newTeeup = () => {
        this.props.history.push('')
        this.props.history.push({
            pathname: '/survey/activities/desktop/',
            state: { newTeeup: true }
        })
    }

    sendAddress = () => {
        const { address } = this.state;
        let addressType = "";

        if (address !== "") {
            validateEmail(address) ? addressType = "EMAIL" : addressType = "PHONE"
            this.props.paymentActions.addPayment(address, addressType)
        } else {
            console.log("Input filed is empty")
        }
        this.setState({ isVisible: true })
    }

    render() {
        return (
            this.state.isVisible ?
                <PopUp
                    message={this.props.paymentStatus}
                    onClose={this.closePopUp}
                    status={this.props.paymentStatus}
                />
                :
                <View
                    addressChange={this.onAddressChange}
                    sendAddress={this.sendAddress}
                    address={this.state.address}
                    newTeeup={this.newTeeup}
                />
        )
    }
}

const mapStateToProps = (state) => ({
    paymentStatus: state.data.paymentStatus,
})

const mapDispatchToProps = (dispatch) => ({
    paymentActions: bindActionCreators(paymentActions, dispatch),
    clearPayment: bindActionCreators(clearPayment, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(AppRedirectContainer)
