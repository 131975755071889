const tagColors = [
    'orange',
    'purple',
    'aqua',
    'red',
    'yellow',
    'blue',
]

const getTagColor = (activeTagIndex) => {
    if (activeTagIndex===undefined || activeTagIndex < 0) return tagColors['darkseagreen']
    return tagColors[ activeTagIndex % tagColors.length ]
}

export {
    getTagColor
}