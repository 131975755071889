import React from 'react'

import './createTeeupStyles.css'

import whenIcon from 'assets/svg/when.svg'
import whereIcon from 'assets/svg/where.svg'

const Header = ({ text }) => {
    return <div className="header"><span className="font-regular-prebig color-gray">{text}</span></div>
}

const InputRow = ({ text, placeholder, icon, disabled = false }) => {
    return <div className="field-input-container">
        {icon ? <img draggable="false" className={'icon'} src={icon} /> : null}
        <input disabled={disabled} className="field-input font-regular-postbig" placeholder={placeholder} defaultValue={text} />
    </div>
}

const InviteesRow = ({ invitees = [] }) => {

    let count = invitees.length
    let peopleSuffix = count == 1 ? 'person' : 'people'

    return <div className="invitees-container">
        <div><span className="font-regular-prebig">{count} {peopleSuffix}</span></div>
        <div className="invitees">
            {invitees.map((invitee, index) => {
                return <div key={index} className="invitee">
                    <img draggable="false" src={invitee.avatar} className="avatar" />
                    <div>{invitee.name}</div>
                </div>
            })}
        </div>
    </div>
}

const Footer = ({ text }) => {
    return <div className="footer"><span className="font-regular-small color-gray">{text}</span></div>
}

const CreateTeeupView = ({ onSend, name, invitees }) => {
    return <div className="teeup-container">
        <div className="teeup-container overlay" />

        <div className="bezel">
            <div className="teeup-header"><span className="font-regular-header">Create a TeeUp</span></div>
            <Header text="Basic Information" />
            <InputRow text={name} placeholder="TeeUp name" />
            <InviteesRow
                invitees={invitees}
            />

            <Header text="Invitation Message (optional)" />
            <InputRow placeholder="Add a message to your invitees" />
            <Footer text="You can include an optional message to describe your TeeUp a bit more to your invitees." />

            <Header text="Game Plan" />
            <InputRow disabled icon={whenIcon} placeholder="Suggest When" />
            <InputRow disabled icon={whereIcon} placeholder="Suggest Where" />

            <div className={"rounded-button bcolor-pink"} onClick={onSend}>
                <span className="rounded-button-text">Invite via Coo-e app</span>
            </div>
        </div>
    </div>
}

export default CreateTeeupView
