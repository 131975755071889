import React, { Component } from 'react'

import { connect } from "react-redux"
import { bindActionCreators } from 'redux'
import loginActions from '../../actions/loginActions'
import queryString from 'query-string'

import View from './loginView'

const checkLoginAndProceed = (props) => {

    if (props.credentials && props.userInfo.id) {
        props.history.push('/survey/basics')
    }
}

const checkSocialLogin = (hash, props) => {

    if (!hash) {
        return
    }

    const parsedHash = queryString.parse(hash)

    props.loginActions.loginSocialCredentials({
        idToken: parsedHash.id_token,
        accessToken: parsedHash.access_token,
    })
}

class LoginContainer extends Component {

    constructor(props) {
        super(props)

        this.state = {
            username: '',
            password: '',
            passwordActive: false,
            usernameActive: false,
            isActive: false,
        }


        checkSocialLogin(props.location.hash, props)

        checkLoginAndProceed(props)
    }

    static getDerivedStateFromProps(nextProps, state) {
        let updates = null

        checkLoginAndProceed(nextProps)

        return updates
    }

    onLogin = () => {
        let { username, password } = this.state

        this.props.loginActions.loginEmail(username, password)
    }

    onLoginFb = () => {
        this.props.loginActions.loginFb()
    }

    onLoginGoogle = () => {
        this.props.loginActions.loginGoogle()
    }

    onPasswordChanged = (event) => {
        this.setState({ password: event.target.value })
        this.isActive(event, "password")
    }

    onUsernameChanged = (event) => {
        let value = event.target.value;
        let username = value.toLowerCase();
        this.setState({ username: username })
        this.isActive(event, "username")
    }


    isActive = (e, val) => {
        const { password, username } = this.state;
        let value = val == "username" ? username : password
        let active = false;
        if (e.target.value !== "" && value !== "")
            active = true;
        else
            active = false;

        this.setState({ isActive: active })
    }

    render() {
        const { location } = this.props;
        let message = location.email ? `User ${location.email} already registered` : ""
        return <View
            message={message}
            onLogin={this.onLogin}
            active={this.state.isActive}
            onLoginGoogle={this.onLoginGoogle}
            onLoginFb={this.onLoginFb}
            onUsernameChanged={this.onUsernameChanged}
            onPasswordChanged={this.onPasswordChanged}
            username={this.state.username}
        />
    }
}

const mapStateToProps = (state) => ({
    credentials: state.user.credentials,
    userInfo: state.user.userInfo,
})

const mapDispatchToProps = (dispatch) => ({
    loginActions: bindActionCreators(loginActions, dispatch),

})

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer)