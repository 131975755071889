import React, { Component } from 'react'

import { connect } from "react-redux"
import { bindActionCreators } from 'redux'
import loginActions from '../../actions/loginActions'

import { validateEmail } from '../../utils/dataUtils'
import View from './contactInfoView'

class ContactInfoContainerTest extends Component {

  constructor(props) {
    super(props)

    this.state = {
      emailUni: '',
      phone: '',
      isValid: true,
      loading: false,
    }

    props.loginActions.initContactInfo()
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.isSent) {
      this.props.history.replace('/verify-info')
    }
    else if (this.state.loading && nextProps.error) {
      this.setState({ loading: false })
    }

    return true
  }

  onNext = async () => {
    if (this.state.isValid) {

      // this.props.history.goBack()
      const { emailUni, phone } = this.state
      this.setState({ loading: true })

      let number = phone

      await this.props.loginActions.sendContactInfo(emailUni, number)

    }
  }

  shouldValidate = (emailUni, phone) => {
    return validateEmail(emailUni) && phone.length > 0
  }

  onChangeUniEmail = (event) => {
    let value = event.target.value
    let email = value.toLoverCase()
    this.setState({ emailUni: email }) // FOR TEST 
  }

  phoneRegex = phone => phone.replace(/[^+\d]+/g, '');

  onChangePhone = (event) => {
    let value = event.target.value
    if (value.length > 0) {
      let phone = this.phoneRegex(value);
      let isValid = this.shouldValidate(this.state.emailUni, phone)
      this.setState({ phone, isValid })
    }
    else {
      this.setState({ isValid: false })
    }
  }

  redirect = () => {
    const { emailUni } = this.state;
    this.props.history.push({
      pathname: '/login',
      email: emailUni
    })
  }

  componentDidMount() {
    this.props.loginActions.clearVerify()
  }

  handleError = error => {
    let errorMessage1 = "";
    let errorMessage2 = ""

    if (error.conflicts.length === 2) {
      errorMessage1 = `${error.conflicts[0].value} and `;
      errorMessage2 = `${error.conflicts[1].value} is already registered `;
    } else {
      errorMessage1 = `${error.conflicts[0].value} is already registered `;
      errorMessage2 = "";
    }

    return errorMessage1 + errorMessage2
  }

  render() {
    const { isValid, loading, emailUni } = this.state
    const { error } = this.props;
    let err = "";
    if (error) {
      // this.redirect()
      err = this.handleError(error)
    }

    return <View
      onNext={this.onNext}
      isValid={isValid}
      onChangeUniEmail={this.onChangeUniEmail}
      onChangeAltEmail={this.onChangeAltEmail}
      onChangePhone={this.onChangePhone}
      loading={loading}
      email={emailUni}
      err={err}
    />
  }
}

const mapStateToProps = (state) => ({
  isSent: state.data.isSent,
  error: state.status.contactInfoSendError,
})

const mapDispatchToProps = (dispatch) => ({
  loginActions: bindActionCreators(loginActions, dispatch),

})

export default connect(mapStateToProps, mapDispatchToProps)(ContactInfoContainerTest)