import React, { Component } from 'react'
import video_path from '../../assets/video/create_teeup_tellus_video.mp4'
import './videoPopUp.css'

export default class VideoPopUp extends Component {
  state = { isVisible: true }

  closePopUp = () => {
    this.setState({ isVisible: false })
  }

  render() {
    const { isVisible } = this.state;
    return isVisible ?
      (
        <div className="popUp-background">
          <div className="video-pop-up">
            <div className="container">
              <h1 className="video-header">Congratulations!</h1>
              <p className="video-txt">You've completed the TellUs survey and have earned the privilege of being one of the first individuals to try out the TeeUp: A next generation social collaboration tool. Watch a video below to learn more about the TeeUp.</p>
              <video className="video" width="640" height="480" controls>
                <source src={video_path} type="video/mp4" ></source>
                Your browser does not support the video tag.
          </video>
              <div className="video-button" onClick={this.closePopUp}>
                <p className="video-btn-txt font-demibold-big">Continue</p>
              </div>
            </div >
          </div>
        </div>
      )
      : null
  }
}
