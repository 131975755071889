import actionTypes from './actionTypes'

const acceptTos = (yesNo) => ({ type: actionTypes.CONTACT_INFO_SET_TOS, payload: yesNo })
const sendContactInfo = (emailUni, phone, countryCode) => ({ type: actionTypes.CONTACT_INFO_SEND, payload: { emailUni, phone, countryCode } })
const verifyContactInfo = (code, flag) => ({ type: actionTypes.CONTACT_INFO_VERIFY, payload: { code, flag } })
const initContactInfo = () => ({ type: actionTypes.CONTACT_INFO_INIT })
const initVerifyInfo = () => ({ type: actionTypes.VERIFY_INFO_INIT })
const contactInfoChange = (body, flag) => ({ type: actionTypes.CONTACT_INFO_CHANGE, payload: { body, flag } });
const logout = () => ({ type: actionTypes.LOGOUT })

const loginGoogle = () => ({ type: actionTypes.LOGIN_GOOGLE })
const loginFb = () => ({ type: actionTypes.LOGIN_FB })
const loginEmail = (email, password) => ({ type: actionTypes.LOGIN_EMAIL, payload: { email, password } })
const loginSocialCredentials = (credentials) => ({ type: actionTypes.LOGIN_SOCIAL_CREDENTIALS, payload: { credentials } })

const fetchContacts = () => ({ type: actionTypes.FETCH_CONTACTS })
const clearVerify = () => ({ type: actionTypes.CLEAR_VERIFY_DATA })

// POZIVAM GETUSER 
const checkContactSync = () => ({ type: actionTypes.CHECK_IS_SYNCHRONED })


export default {
    acceptTos,
    sendContactInfo,
    verifyContactInfo,
    initContactInfo,
    initVerifyInfo,
    contactInfoChange,
    logout,

    loginGoogle,
    loginFb,
    loginEmail,
    loginSocialCredentials,

    fetchContacts,
    clearVerify,

    checkContactSync
}