import dataReducer from './dataReducer'
import stepsReducers from './stepsReducers'
import statusReducer from './statusReducer'
import activitiesReducer from './activitiesReducer'
import userReducer from './userReducer'
import teeupReducer from './teeupReducer'
import desktopReducer from './desktopReducer'
import interestsRecure from './interestsReducer'

export default {
    data: dataReducer,
    steps: stepsReducers,
    status: statusReducer,
    activities: activitiesReducer,
    user: userReducer,
    teeup: teeupReducer,
    desktop: desktopReducer,
    interests: interestsRecure,
}
