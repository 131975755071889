import React from 'react'
import { Dropdown, Menu } from 'antd'
import { DragDropContainer, DropTarget } from 'react-drag-drop-container'
import BoxItem from './boxItem'
import uuidv1 from 'uuid/v1'
import InfiniteScroll from 'react-infinite-scroller'

import { getTagColor } from 'utils/stylesUtils'
import tagIcons from './tagIcons'
import attributesIcons from './attributesIcons'

import './boxActivities.css'
const getUsersSvg = (color) => {
    return <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="users"
        className="svg-inline--fa fa-users fa-w-20 group-tag-icon" role="img" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 640 512">
        <path fill={color}
            d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z"></path>
    </svg>
}

export default class Box extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [
                { label: 'Ada Denisova1', uid: uuidv1() },
                { label: 'Sebastian Bennett', uid: uuidv1() },
            ]
        };
    }

    handleDrop = (e) => {
        let items = this.state.items.slice();
        items.push({ label: e.dragData.label, uid: uuidv1() })
        this.setState({ items: items });
        e.containerElem.style.visibility = "hidden";
    }

    swap = (fromIndex, toIndex, dragData) => {
        let items = this.state.items.slice();
        const item = { label: dragData.label, uid: uuidv1() }
        items.splice(toIndex, 0, item);
        this.setState({ items: items });
    };

    kill = (uid) => {
        let items = this.state.items.slice();
        const index = items.findIndex((item) => {
            return item.uid == uid
        });
        if (index !== -1) {
            items.splice(index, 1);
        }
        this.setState({ items: items });
    }

    loadMore = (page) => {

    }

    renderItem = (item, index) => {
        const canTriggerContext = true;

        let text = "none"
        let icon = null
        switch (item.type) {
            case 'tag':
                text = item.name
                icon = <img draggable="false" className='activity-tag ' src={tagIcons[item.index]} />
                break
            case 'group':
                text = item.name
                icon = getUsersSvg(getTagColor(index))
                break
            case 'attribute':
                text = item.attribute
                icon = <img draggable="false" className='activity-tag ' src={attributesIcons[item.index - 1]} />
                break
        }

        const content = (
            <div
                key={index}
                className="box-activity-item"
            >
                {icon}
                {text}
            </div>
        )

        if (!canTriggerContext) {
            return content;
        }

        const menu = (
            <Menu>
                <Menu.Item
                    key="create-teeup"
                    onClick={() => {
                        this.props.onCreateTeeup(item)
                    }
                    }
                >
                    Create TeeUp
                </Menu.Item>
            </Menu >
        )

        return (
            <Dropdown
                key={index}
                overlay={menu}
                trigger={['contextMenu']}
            >
                {content}
            </Dropdown>
        )
    }

    renderHeader = (title) => {
        return <div className="tab_header font-demibold-postbig" key={title}>{title}</div>
    }

    getItemBox = (items) => {
        return <div style={{ background: 'red', margin: 10 }}>{items}</div>
    }

    render() {
        /*
            Note the two layers of DropTarget. 
            This enables it to handle dropped items from 
            outside AND items dragged between boxes.
        */


        let { tags, groups, attributes, newAttributes } = this.props

        let renderedItemsTag = []
        let renderedItemsGroup = []
        let renderedItemsAttr = []

        if (tags && tags.length > 0) {
            renderedItemsTag.push(this.renderHeader('Activities'))
        }

        tags.forEach(element => {
            renderedItemsTag.push(this.renderItem(element, renderedItemsTag.length))
            console.log(element, "element item")

        })

        if (groups && groups.length > 0) {
            let grouPcheck = false;
            groups.forEach(element => {
                if (Object.keys(element.lines).length !== 0) {
                    grouPcheck = true;
                }
            })
            if (grouPcheck) {
                renderedItemsGroup.push(this.renderHeader('Groups'))
            }

        }

        groups.forEach(element => {
            console.log(element, "element group")
            if (Object.keys(element.lines).length !== 0) {
                renderedItemsGroup.push(this.renderItem(element, renderedItemsGroup.length))
            }
        })

        console.log(attributes, "attributes")

        if (attributes && attributes.length > 0) {

            renderedItemsAttr.push(this.renderHeader('Attributes'))

        }

        attributes.forEach(element => {
            if (element.contacts) {
                renderedItemsAttr.push(this.renderItem(element, renderedItemsAttr.length))
            }

        })

        return (
            <div className="box_container_tags">
                <div className="box_title font-demibold-header">Tags</div>
                {/* don't have a scroll here */}
                <div className="box boxScrollHiden">
                    <DropTarget
                        onHit={this.handleDrop}
                        targetKey="boxItem"
                        dropData={{ name: this.props.name }}
                    >
                        <div className="groups" style={{ overflow: 'hiden' }}>
                            <div className="groupsItem">
                                {renderedItemsTag}
                            </div>
                            <div className="groupsItem">
                                {renderedItemsGroup}
                            </div>
                            <div className="groupsItem">
                                {renderedItemsAttr}
                            </div>
                        </div>
                    </DropTarget>
                </div>
            </div>
        )
    }
}