import React, { Component } from 'react'

import View from './createTeeupView'

class CreateTeeupContainer extends Component {

    constructor(props) {
        super(props)

    }

    onSend = () => {
        alert('Check your app!')
    }

    render() {

        const { teeupName, contacts } = this.props.teeupInformation
        return <View
            name={teeupName}
            onSend={this.onSend}
            invitees={contacts}
        />
    }
}

export default CreateTeeupContainer