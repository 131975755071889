import React, { Component } from 'react'
import { connect } from "react-redux"
import { bindActionCreators } from 'redux'
import desktopActions from 'actions/desktopActions'

class TeeupWaiting extends Component {
  state = {
    newNumber: 0,
    teeup: 0,
  }


  teeupChecking = () => {
    this.inteval = setInterval(() => {
      this.props.desktopActions.getTeeUp();
    }, 5000);


  }
  componentDidMount() {
    this.setState({
      teeup: this.props.teeupsLength
    });
    this.teeupChecking()
  }

  // static getDerivedStateFromProps(nextProps, state) {
  //   let updates = null

  //   if (nextProps.teeupsLength !== state.teeup) {
  //     updates = { ...nextProps.teeupsLength }
  //   }

  //   return updates
  // }

  componentDidUpdate(prevProps) {

    if (prevProps.teeupsLength === null) {
      return
    }

    if (prevProps.teeupsLength !== undefined) {
      if (this.props.teeupsLength !== prevProps.teeupsLength) {
        clearInterval(this.inteval)
        this.props.history.push('last')
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.inteval)

  }

  render() {
    return (
      <div>
        <h1 className='font-demibold-headerBig' style={{ width: '50%', margin: '20% auto', textAlign: 'center' }}>
          Please go to the app - after a brief wait a draft TeeUp will appear. Please edit the details and select "Create TeeUp" and send it out. After the TeeUp is created and Really sent out, you will be linked in TellUs to a payout screen.
Once you manage to TeeUP you will receive additional payment.</h1>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  teeupsLength: state.teeup.teeupsLength,
})
const mapDispatchToProps = (dispatch) => ({
  desktopActions: bindActionCreators(desktopActions, dispatch),
})
export default connect(mapStateToProps, mapDispatchToProps)(TeeupWaiting)