import React from 'react'

import './boxItem.css'

/*
    BoxItem - a thing that appears in a box, after you drag something into the box
*/

export default class BoxItem extends React.Component {
    // the things that appear in the boxes
    constructor(props) {
        super(props)
    }

    render() {
        /*
          Notice how these are wrapped in a DragDropContainer (so you can drag them) AND
          in a DropTarget (enabling you to rearrange items in the box by dragging them on
          top of each other)
        */

        let { name, uid, index } = this.props

        return <div className={"outer box_item_component"} >
            {/* <div className={"item" + (hasTags ? ' tagged': '')}> */}
            <div className="item bucket_item" onClick={this.onTap}>
                <div className="image">

                </div>
                <div className="content">
                    <div className="name">{name}</div>
                </div>

                <div style={{ flex: 1 }} />
                <div onClick={this.props.onRemove} className="close">x</div>
            </div>
        </div>
    }
}