import actionTypes from './actionTypes'

const sendContactGroups = (groups) => ({ type: actionTypes.SEND_CONTACT_GROUPS, payload: groups })
const sendActivityGroups = (groups) => ({ type: actionTypes.SEND_ACTIVITY_GROUPS, payload: groups })
const sendAttributeGroups = (groups) => ({ type: actionTypes.SEND_ATTRIBUTE_GROUPS, payload: groups })
const sendBucketGroups = (groups) => ({ type: actionTypes.SEND_BUCKET_GROUPS, payload: groups })
const addContacts = (name, email, phone, countryCode) => ({ type: actionTypes.ADD_CONTACT, payload: { name, email, phone, countryCode } })
const editContact = (name, id) => ({ type: actionTypes.EDIT_CONTACT, payload: { name, id } });
const formatContact = () => ({ type: actionTypes.FORMAT_CONTACT });

const getRecommendedTeeUp = (contactIds) => ({ type: actionTypes.GET_RECOMMENDED_TEEUP, payload: { contactIds } })
const getTeeUp = (contactIds) => ({ type: actionTypes.GET_TEEUP, payload: { contactIds } })
const postDraftedTeeUp = (name, location, inviteMessage, contactIds, obj) => ({ type: actionTypes.POST_DRAFTED_TEEUP, payload: { name, location, inviteMessage, contactIds, obj } })
const updateTeeUp = (payload) => ({ type: actionTypes.UPDATE_TEEUP, payload, })

const saveState = (payload) => ({ type: actionTypes.DESKTOP_SAVE, payload, })
const deletedUid = (payload) => ({ type: actionTypes.DELETED_UID, payload })
const postDesktopState = (payload) => ({ type: actionTypes.POST_DESKTOP_STATE, payload, })
const updateContacts = (data, count) => ({ type: actionTypes.UPDATE_CONTACTS, payload: { data, count } })
const deleteGroup = (payload) => ({ type: actionTypes.DELETE_GROUP, payload })

export default {
    sendContactGroups,
    sendActivityGroups,
    sendAttributeGroups,
    sendBucketGroups,
    addContacts,
    getTeeUp,
    editContact,
    getRecommendedTeeUp,
    postDraftedTeeUp,
    updateTeeUp,
    saveState,
    deletedUid,
    postDesktopState,
    formatContact,
    updateContacts,
    deleteGroup
}
