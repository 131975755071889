import React from 'react'
import _ from "lodash";

const ActivitiesSelectorView = ({ onValueChange, status, topText, bottomText, name, secoundaryText }) => {
    return (
        <div style={{ dispaly: 'flex' }}>
            <div className="input-container radio-padding selector-radio-buttons" >
                <div className="selector-text-container" style={{ flex: 10, height: 90 }}>
                    <span className="selector-text-top txt_top">{topText}</span>
                    <span className="secoundary-text">{secoundaryText}</span>
                    <span className="selector-text-bottom txt_botom">{bottomText}</span>
                </div>
                <div className="input-form-radio " style={{ flex: 4, }}>
                    <form>
                        <label className="input-radio-label" style={{ position: 'relative', top: 45 }}>
                            <input name="isOutOfStateStudent" value={true}
                                className="radio-input"
                                onChange={(event) => onValueChange(event, name)}
                                checked={status === 'true' || status === true}
                                type="radio" /> Yes </label>
                        <label className="input-radio-label" style={{ position: 'relative', top: 45 }}>
                            <input name="isOutOfStateStudent" value={false}
                                className="radio-input"
                                onChange={(event) => onValueChange(event, name)}
                                checked={status === 'false' || status === false}
                                type="radio" /> No </label>
                    </form>
                </div>
            </div>
            {/* 
             */}
        </div>
    )
}

export default ActivitiesSelectorView