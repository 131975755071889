import actionTypes from './actionTypes'

// const authGoogle = () => ({ type: actionTypes.AUTH_GOOGLE })
const setActivityQuestions = (questions, activity) => ({ type: actionTypes.SET_ACTIVITY_QUESTIONS, payload: { questions, activity } })
const sendBasicsQuestions = (questions) => ({ type: actionTypes.SEND_BASICS_QUESTIONS, payload: { questions } })
const sendActivityQuestions = () => ({ type: actionTypes.SEND_ACTIVITY_QUESTIONS })
const fetchDemographicsMeta = () => ({ type: actionTypes.GET_DEMOGRAPHICS_META })
const setActivitySelector = (type, value) => ({ type: actionTypes.SET_ACTIVITY_SELECTOR, payload: { type, value } })

export default {
    setActivityQuestions,
    sendBasicsQuestions,
    sendActivityQuestions,
    fetchDemographicsMeta,
    setActivitySelector,
}