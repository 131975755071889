import React, { Component } from 'react'
import hmtlParser from 'html-react-parser'

export default class InterestExample extends Component {

  exampleText = `<span>The images shown below illustrate how your interests can be used to coordinate with other through the Coo-e app.</span>
  <br/>
  <br/>
  <span>Coo-e offers users the ability to coordinate with new people based on their shared interests. For example, the image on the left shows a list of a user’s personal interests. The middle image shows the same user looking to coordinate with others based on a specific interest; in this case its Volleyball. The right image shows other nearby Coo-e users who are also interested in coordinating a Volleyball game.</span>`

  exampleImages = [
    // community,
    { src: require('../../../assets/img/phone/Me.png') },
    { src: require('../../../assets/img/phone/filter.png'), },
    { src: require('../../../assets/img/phone/Community.png') },

  ]

  onNext = () => {
    this.props.history.push('interestsList')
  }

  onBack = () => {
    this.props.history.push('/survey/activities/quiz')
  }

  render() {
    return (
      <div>
        <div className="activity-table-example" style={{ width: "45%", margin: '0 auto' }}>
          <p style={{ fontFamily: 'Helvetica', fontSize: '16px', lineHeight: '21px', letterSpacing: '-0.4px' }}><b>{hmtlParser(this.exampleText)}</b></p>
        </div>
        <div className="interest-example-images" style={{ display: 'flex', width: "45%", margin: '0 auto', marginTop: '60px' }}>
          {this.exampleImages.map(item => (
            // <img scr={item.src} style={{ flex: 1, height: '400px', boxSizing: 'border-box', padding: '10px' }} />
            // <div style={{ flex: 1, height: '489px', width: '100px', boxSizing: 'border-box', backgroundImage: `url(${item.src})`, backgroundPosition: 'center', backgroundSize: '230px', backgroundRepeat: 'no-repeat', margin: "20px" }} />
            <div style={{ display: "flex", alignContent: 'center', alignItems: 'center', justifyContent: 'center', width: '300px' }}>
              <img src={item.src} style={{ height: '400px' }} />
            </div>
          ))}
        </div>
        <div style={{ paddingTop: '137px' }} />
        <div className='activity-button-container'>
          <div className={"rounded-button bcolor-pink"} onClick={this.onBack}>
            <span className="rounded-button-text">Back</span>
          </div>
          <div className={"rounded-button bcolor-pink"} onClick={this.onNext}>
            <span className="rounded-button-text">Next</span>
          </div>
        </div>
      </div >
    )
  }
}
