import tagIcon1 from 'assets/svg/tagIcon1.svg'
import tagIcon2 from 'assets/svg/tagIcon2.svg'
import tagIcon3 from 'assets/svg/tagIcon3.svg'
import tagIcon4 from 'assets/svg/tagIcon4.svg'
import tagIcon5 from 'assets/svg/tagIcon5.svg'
import tagIcon6 from 'assets/svg/tagIcon6.svg'

export default [
    tagIcon1,
    tagIcon2,
    tagIcon3,
    tagIcon4,
    tagIcon5,
    tagIcon6,
    
    tagIcon1,
    tagIcon2,
    tagIcon3,
    tagIcon4,
    tagIcon5,
    tagIcon6,

    tagIcon1,
    tagIcon2,
    tagIcon3,
    tagIcon4,
    tagIcon5,
    tagIcon6,

    tagIcon1,
    tagIcon2,
    tagIcon3,
    tagIcon4,
    tagIcon5,
    tagIcon6,

    tagIcon1,
    tagIcon2,
    tagIcon3,
    tagIcon4,
    tagIcon5,
    tagIcon6,
]