import React, { Component } from 'react'

import { connect } from "react-redux"
import { bindActionCreators } from 'redux'
import loginActions from '../../actions/loginActions'

import View from './startView'

class StartContainer extends Component {

    constructor(props) {
        super(props)
    }

    onStart = () => {
        if (this.props.tosAccepted) {
            this.props.history.push('contact-info')
        }
    }

    onAcceptTos = () => {
        this.props.loginActions.acceptTos(!this.props.tosAccepted)
        // if (this.props.tosAccepted) {
        //     this.props.loginActions.acceptTos(false)
        // }
        // else {
        //     window.scrollTo(0, 0)
        //     this.props.history.push('tos')
        // }
    }

    render() {
        return <View
            onStart={this.onStart}
            onAcceptTos={this.onAcceptTos}
            tosAccepted={this.props.tosAccepted}
        />
    }
}

const mapStateToProps = (state) => ({
    tosAccepted: state.data.tosAccepted,
})

const mapDispatchToProps = (dispatch) => ({
    loginActions: bindActionCreators(loginActions, dispatch),

})

export default connect(mapStateToProps, mapDispatchToProps)(StartContainer)