import React, { Component } from 'react'
import { connect } from "react-redux"
import './StepIndicatorStyle.css'
import stepActions from '../../actions/stepActions'
import { bindActionCreators } from 'redux'

class StepIndicator extends Component {
    constructor(props) {
        super(props)
    }

    pressStep = (index) => {
        // this.props.stepActions.setActiveStep(index)
    }

    render() {
        const {steps, activeStepIndex} = this.props

        return (
            <div className="form-bootstrapWizard clearfix page-indicator-container">
                <ul className="bootstrapWizard" >
                    {steps.map((step, index) => (
                        <li key={index}>
                                <a onClick={()=>this.pressStep(index)}>
                                <span className={(step.finished || activeStepIndex === index) ? 'step active-step' : 'step'}>
                                    {
                                        step.finished
                                        ? <i className="check"></i>
                                        : <span className="step-inside">{index + 1}</span>
                                    }
                            </span>
                                <span className={(step.finished || activeStepIndex === index) ? 'title title-complete' : 'title'}>{step.title}</span>
                            </a>
                        </li>
                        )
                    )}
                </ul>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    steps: state.steps.structure,
    activeStepIndex: state.steps.currentIndex
})

const mapDispatchToProps = (dispatch) => ({
    stepActions: bindActionCreators(stepActions, dispatch),

})

export default connect(mapStateToProps, mapDispatchToProps)(StepIndicator)