import attriubeIcons1 from 'assets/svg/attributesIcon1.svg'
import attriubeIcons2 from 'assets/svg/attributesIcon2.svg'


export default [
  attriubeIcons1,
  attriubeIcons2,
  // tagIcon3,
  // tagIcon4,
  // tagIcon5,
  // tagIcon6,

  // tagIcon1,
  // tagIcon2,
  // tagIcon3,
  // tagIcon4,
  // tagIcon5,
  // tagIcon6,

  // tagIcon1,
  // tagIcon2,
  // tagIcon3,
  // tagIcon4,
  // tagIcon5,
  // tagIcon6,

  // tagIcon1,
  // tagIcon2,
  // tagIcon3,
  // tagIcon4,
  // tagIcon5,
  // tagIcon6,

  // tagIcon1,
  // tagIcon2,
  // tagIcon3,
  // tagIcon4,
  // tagIcon5,
]